/* ELEMENTS
  --------------------------------------- */
@mixin wp-full-width-no-padding {
  width: 100vw;
  max-width: 100vw;
  margin-left: -50vw;
  left: 50%;
  position: relative;
}
@mixin block-padding-vert {
  padding-top: $grid-gutter-height*4;
  padding-bottom: $grid-gutter-height*3;
  @include media-breakpoint-up(sm) {
    padding-bottom: $grid-gutter-height*4;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: $grid-gutter-height*8;
  }
}
@mixin block-margin-vert {
  margin-top: $grid-gutter-height*4;
  margin-bottom: $grid-gutter-height*7;
}
@mixin block-margin-vert-identical {
  margin-top: $grid-gutter-height*4;
  margin-bottom: $grid-gutter-height*4;
}
@mixin block-margin-vert-sm {
  margin-top: $grid-gutter-height*1.5;
  margin-bottom: $grid-gutter-height*3;
}
@mixin last-child-zero-margin {
  :last-child {
    margin-bottom: 0;
  }
}
