aside.sidebar {
  margin-top: $grid-gutter-width*1.5;
  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }
}
.solution-details,.milestones {
  @include media-breakpoint-up(lg) {
    margin-left: 10%;
    padding-left: 15%;
    border-left: $border-standard;
    height: 100%;
  }
  .statistic {
    @extend h3;
    margin-bottom: $grid-gutter-width*2;
    line-height: 1.3;
    p {
      margin-bottom: $grid-gutter-width*.4;
      font-family: $font-family-sans-title
    }
    small {
      display: block;
      font-size: $font-size-sm;
      color: $gray1;
      line-height: 1.25;
    }
  }
  nav {
    margin-bottom: $grid-gutter-width*2;
    &.related {
      h5 {
        color: $gray2;
        margin-bottom: .5em;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          margin-bottom: $grid-gutter-width*.5;
//           font-weight: $font-weight-base;
        }
      }
    }
  }
  .cta {
    margin: $grid-gutter-width*3.5 0 $grid-gutter-width*.5;

  }
}
.milestones {
  ul {
    @extend .bullet-check;
    li {
      background-image: url(../images/checkmark.svg);
    }
  }
}
