a.btn,button.btn,input.btn {
  min-width: $btn-min-width;
  min-height: $btn-min-height;
  box-shadow: $btn-box-shadow;
  border-radius: $border-radius;
  font: $btn-font-weight $btn-font-size $btn-font-family;
  line-height: $btn-line-height;
  cursor: pointer;
  color: $btn-default-color;
  background-color: $btn-default-bg;
  border: 1px solid $btn-default-border;
  white-space: normal;
  @include hover-focus {
    color: $btn-default-color-hover;
    background-color: $btn-default-hover;
    border-color: $btn-default-hover;
    outline:none;
  }
  &:active {
    outline:none;
  }
  &.btn-thin {
    font-weight: $font-weight-base;
    font-size: $font-size-base;
    line-height: 1.2;
    min-height: 44px;
    min-width: 90px;
    padding: $btn-padding-y-fluid $btn-padding-x-thin;
    color: $navy;
    background-color: transparent;
    border-color: $gray2;
    @include hover-focus {
      color: $navy;
      background-color: transparent;
      border-color: $navy;
    }
    &.btn-light {
      border-color: $gray4;
      @include hover-focus {
        border-color: $navy;
      }
    }
  }
/*
  &.btn-default {
    color: $btn-default-color;
    background-color: $btn-default-bg;
    border-color: $btn-default-border;
    @include hover-focus {
      color: $btn-default-color;
      background-color: $btn-default-hover;
      border-color: $btn-default-hover;
    }
  }
*/
  &.btn-default-dark {
    color: $btn-default-dark-color;
    background-color: $btn-default-dark-bg;
    border-color: $btn-default-dark-border;
    @include hover-focus {
      &:not(:disabled) {
        color: $btn-default-dark-color-hover;
        background-color: $btn-default-dark-hover;
        border-color: $btn-default-dark-hover;
      }
    }
  }
  &.btn-stroke {
    color: $btn-stroke-color;
    background-color: $btn-stroke-bg;
    border-color: $btn-stroke-border;
    @include hover-focus {
      color: $btn-stroke-color-hover;
      background-color: $btn-stroke-hover;
      border-color: $btn-stroke-hover;
    }
  }
  &.btn-primary {
    color: $btn-primary-color;
    background-color: $btn-primary-bg;
    border-color: $btn-primary-border;
    @include hover-focus {
      color: $btn-primary-color;
      background-color: $btn-primary-hover;
      border-color: $btn-primary-hover;
    }
    &.btn-thin {
      color: $btn-primary-color;
      background-color: $btn-primary-bg;
      border-color: $btn-primary-border;
      @include hover-focus {
        color: $btn-primary-color;
        background-color: $btn-primary-hover;
        border-color: $btn-primary-hover;
      }
    }
  }
  &.btn-secondary {
    color: $btn-secondary-color;
    background-color: $btn-secondary-bg;
    border-color: $btn-secondary-border;
    @include hover-focus {
      color: $btn-secondary-color;
      background-color: $btn-secondary-hover;
      border-color: $btn-secondary-hover;
    }
  }
  &.btn-blue-light {
    color: $btn-blue-light-color;
    background-color: $btn-blue-light-bg;
    border-color: $btn-blue-light-border;
    @include hover-focus {
      color: $white;
      background-color: $btn-blue-light-hover;
      border-color: $btn-blue-light-hover;
    }
    &.btn-thin {
      color: $btn-blue-light-color !important;
      background-color: $btn-blue-light-bg !important;
      border-color: $btn-blue-light-border !important;
      @include hover-focus {
        color: $white !important;
        background-color: $btn-blue-light-hover !important;
        border-color: $btn-blue-light-hover !important;
      }
    }

  }
}
