footer.content-info {
  margin-top: $grid-gutter-width*5;
  @include media-breakpoint-up(lg) {
    margin-top: $grid-gutter-width*7.5;
  }
  @include media-breakpoint-up(xl) {
    margin-top: $grid-gutter-width*9;
  }
  .home &,.schedule-demo &,.page-template-template-calendly & {
    margin-top: 0;
  }
  .copyright {
    font-size: $font-size-xs;
    color: $gray2;
    @include media-breakpoint-down(md) {
      margin: $grid-gutter-width 0 0;
    }
  }
  .footer-menus {
//     border-top: 1px solid $gray5;
    padding: $grid-gutter-width*3 0;
    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width*5 0;
    }
    .brand {
//       margin-bottom: $grid-gutter-width*2;
      .tagline {
        line-height: 1.6;
      }
      img {
        width: $grid-gutter-width*2.5;
        margin-bottom: $grid-gutter-width*1.2;
      }
    }
    .menus {
      font-size: $font-size-sm;
      margin-top: $grid-gutter-width*.5;
      h5 {
        margin-bottom: 1.25em;
      }
      .menu {
        list-style: none;
        margin: 0 0 $grid-gutter-width*1.5;
        padding: 0;
        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }
        li {
          line-height: 1.1;
          margin: 0 0 $grid-gutter-width*.6;
          a {
            color: $body-color;
            @include hover-focus {
              color: $link-color !important;
              text-decoration: none;
            }
            b {
              @extend .nav-tag-highlight;
              vertical-align: text-bottom;
            }
          }
        }
      }
    }
  }
  .footer-request {
    section.extra-wrap {
      @extend .bg-purple-dark;
      padding: $grid-gutter-height*3 0;
      @include media-breakpoint-up(sm) {
        padding: $grid-gutter-height*3;
      }
      @include media-breakpoint-up(md) {
        padding: $grid-gutter-height*5 0;
      }
      &>.container-fluid {
        @include media-breakpoint-up(lg) {
          max-width: 960px;
        }
        @include media-breakpoint-up(xl) {
          max-width: 1200px;
        }
        &>.row {
          align-items: center;
        }
      }

/*
      @include media-breakpoint-down(md) {
        padding: $grid-gutter-width*3 0;
        &>.container {
          width: 100%;
          padding: 0;
          &>.row {
            margin: 0;
          }
        }
      }
*/
      .clients {
        h2 {
          text-align: center;
          @include media-breakpoint-up(lg) {
            text-align: left;
          }
        }
        .client-logos {
          text-align: center;
          max-width: 380px;
          padding: 0 $grid-gutter-width*1.25;
          margin: 0 auto;
          @include media-breakpoint-up(lg) {
            padding: 0;
            margin: 0;
            text-align: left;
          }
          img.client-logo {
            display: inline-block;
            margin: $grid-gutter-width*.75;
            @include media-breakpoint-up(lg) {
              margin: $grid-gutter-width*.75 $grid-gutter-width*1.5 $grid-gutter-width*.75 0;
            }
            max-width: 200px;
            max-height: 55px;
          }
        }
        @include media-breakpoint-down(md) {
          margin-bottom: $grid-gutter-height*1.8;
        }
      }
      .action {
        align-self: center;
        form.asa {
          border: 1px solid rgba($white,.1);
          border-radius: $border-radius-extra/2;
          @include media-breakpoint-up(lg) {
            border-radius: $border-radius-extra;
          }
          padding: $grid-gutter-width*2 $grid-gutter-width*2.25 $grid-gutter-width*1.5;
          .form-group {
            display: flex;
            flex-direction: column-reverse;
            label {
              color: $gray4;
            }
            .form-control {
              border-color: transparent;
              background-color: $input-bg-alt;
              &:focus {
                background-color: $input-bg;
                @include transition($link-transition);
                &+label {
                  @include transition($link-transition);
                  color: $brand-primary;
                }
              }
            }
          }
          .btn {
            margin-top: 1rem;
            width: 100%;
          }
          .schedule-time {
            margin: $grid-gutter-width*1.25 0 0;
            font-size: $font-size-xs;
            color: $gray3;
            text-align: center;
            a {
              color: $gray5;
            }
          }
        }
      }
/*
      @include media-breakpoint-down(md) {
        .clients {
          padding-top: 0;
          padding-bottom: $grid-gutter-width*3;
          border-bottom: 1px solid rgba($white,.1);
          .client-logos {
            padding: 0;
            img.client-logo {
              margin: $grid-gutter-width*.6;
              max-height: 50px;
            }
          }
        }
        .clients h2,.action {
          padding-left: $grid-gutter-width;
          padding-right: $grid-gutter-width;
          margin-left: auto;
          margin-right: auto;
          max-width: 400px;
        }
        .action {
          form {
            padding: $grid-gutter-width*2.5 0 0;
            border: none;
          }
        }
      }
*/
    }
  }
}
