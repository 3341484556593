// Sortable Patients Experience table
.dataTables_wrapper {
  width: 100%;
  &>.row {
    width: 100%;
    margin: 0;
    &>.col-xs-12 {
      width: 100%;
    }
    &:first-child {
      display: none;
    }
  }
  .table {
    font: $font-weight-base $font-size-sm $font-family-sans-ui;
    letter-spacing: .02em;
    border-spacing: inherit;
    margin: 0 !important;
    width: 100%;
    th,td {
      border: none;
      border-bottom: 1px solid $gray5;
      vertical-align: middle;
      padding: $grid-gutter-width*.5;
      padding-right: $grid-gutter-width*1.5;
      white-space: nowrap;
    }
    tr {
      th,td {
        &:first-child {
          padding-right: $grid-gutter-width*.5;
        }
      }
    }
    thead {
      tr {
        th {
          text-transform: uppercase;
          font-weight: $font-weight-med;
          font-size: $font-size-xs;
          color: $gray2;
          position: relative;
          background-image: none !important;
          padding: $grid-gutter-width*.5;
          &.sorting,&.sorting_asc,&.sorting_desc {
            padding-right: $grid-gutter-width*.5;
            &:before {
              display: none !important;
            }
            &:after {
              position: relative;
              display: inline;
              top: auto;
              right: auto;
              bottom: auto;
              opacity: 1;
              font: 900 $font-size-h5 "Font Awesome 5 Free";
              color: $gray1;
              margin-left: $grid-gutter-width*.4;
            }
          }
          &.sorting:after {
            content: "\f062";
            color: transparent;
          }
          &.sorting_asc:after {
            content: "\f062";
          }
          &.sorting_desc:after {
            content: "\f063";
          }
        }
      }
    }
    tbody {
      tr {
        td {
          img {
            width: $grid-gutter-width*1.3;
            margin-top: -$grid-gutter-width*.2;
          }
/*
          &.select-checkbox {
            &:before {
              cursor: pointer;
              font: $font-size-base "Font Awesome 5 Free";
              margin: 0;
              border: none;
              border-radius: 0;
              display: block;
              position: relative;
              top: auto;
              left: auto;
              width: auto;
              height: auto;
              box-sizing: inherit;
              content: "\f044";
              color: $blue;
            }
          }
*/
        }
        &.selected {
          background-color: $gray5;
          color: $body-color;
          td {
            &.select-checkbox {
              &:after {
                display: none;
              }
              &:before {
                content: "\f14a";
                padding: 1px 4px 0 0;
              }
            }
          }
        }
      }
    }
    .initials {
      width: $grid-gutter-width*1.75;
      height: $grid-gutter-width*1.75;
      display: inline-block;
      border-radius: $grid-gutter-width*.875;
      text-align: center;
      color: $white;
      font: $font-weight-bold $font-size-sm $font-family-base;
      line-height: $grid-gutter-width*1.75;
      text-transform: uppercase;
    }
  }
}
