.ohmd-block {
  @include block-margin-vert;
  &.ohmd-block-sm {
    @include block-margin-vert-sm;
  }
}
main.main {
  :last-child {
    &.ohmd-block {
      margin-bottom: 0;
    }
  }
}
lottie-player {
  &>svg {
    transform: unset !important;
  }
}
.full-width {
  @include wp-full-width-no-padding;
}
.divider-block {
  @include block-margin-vert-identical;
}
.descript-block {
  .extra-wrap {
    padding: $grid-gutter-height*3 0;
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-height*4 0;
    }
    .overview {
      font-size: $font-size-lg;
      text-align: justify;
      h2 {
        text-align: center;
      }
    }
    .descript-tile {
      article {
        border-radius: $border-radius-extra;
        padding: $grid-gutter-height;
        @include media-breakpoint-up(sm) {
          padding: $grid-gutter-height*2;
        }
        .descript-embed {
          text-align: center;
          margin-bottom: $grid-gutter-height*3;
          @include media-breakpoint-up(lg) {
            text-align: left;
            margin-bottom: 0;
          }
          iframe {
            max-width: 248px;
            max-height: 248px;
            @include media-breakpoint-up(sm) {
              max-width: 348px;
              max-height: 348px;
            }
          }
        }
        .descript-details {
          @include last-child-zero-margin;
          font-size: $font-size-lg;
          h3 {
            &+h4 {
              @extend h5;
              margin: -1.4em 0 1.75em;
            }
          }
          small {
            @include last-child-zero-margin;
            display: block;
            font-size: $font-size-base;
          }
        }
      }
    }
    &.bg-yellow-light {
      .descript-tile {
        article {
          background-color: rgba($yellow,.2);
        }
      }
    }
  }
}
.hero-block {
  &>.row {
    align-items: center;
  }
  .details {
    font-size: $font-size-lg;
    p {
      color: $gray;
    }
    h1.h2 {
      margin-bottom: .75em;
    }
  }
}
.product-feature {
  @include block-padding-vert;
  .media {
    @include make-col-ready();
    @include make-col(12);
    @include media-breakpoint-up(lg) {
      @include make-col(5);
    }
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }
  .details {
    @include make-col-ready();
    @include make-col(12);
    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }
  }
  @include media-breakpoint-up(lg) {
    &>.row {
      flex-direction: row-reverse;
      .media {
        @include make-col-offset(1);
        figure {
          margin-right: -330px;
          min-width: 710px;
          img,lottie-player {
            max-width: 346px;
            min-width: 346px;
          }
        }
      }
    }
    &+.product-feature:not(:nth-child(even)) {
      &>.row {
        flex-direction: row;
        .media {
          @include make-col-offset(0);
          figure {
            margin-right: 0;
            margin-left: -330px;
            text-align: right;
            img,lottie-player {
              margin-left: auto;
            }
          }
        }
        .details {
          @include make-col-offset(1);
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    &>.row {
      .media {
        figure {
          margin-right: -108px;
          min-width: 0;
          img,lottie-player {
            max-width: 460px;
            min-width: 460px;
          }
        }
      }
    }
    &+.product-feature:not(:nth-child(even)) {
      &>.row {
        .media {
          figure {
            margin-left: -108px;
          }
        }
      }
    }
  }
}
.product-feature,.customizable-feature {
  &>.row,&>.container-extra>.row  {
    align-items: center;
  }
  .media {
    figure {
      border-radius: $border-radius-extra;
      padding: $grid-gutter-height;
      margin-bottom: $grid-gutter-height*4.5;
      @include media-breakpoint-only(md) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        img {
          max-width: 560px;
          min-width: 560px;
        }
      }
      @include media-breakpoint-up(md) {
        border-radius: $border-radius-extra*2;
        padding: $grid-gutter-height*3;
      }
      @include media-breakpoint-up(lg) {
        padding: $grid-gutter-height*4;
        margin-bottom: 0;
      }
      img,lottie-player {
        @extend .img-fluid;
//         display: inline-block;
        display: block;
      }
    }
  }
  .details {
    @include last-child-zero-margin;
    font-size: $font-size-lg;
    h2 {
      margin-bottom: .75em;
    }
    .product-feature-links {
      margin-bottom: $grid-gutter-height;
      .product-feature-link {
        font-size: $font-size-sm;
        display: inline-block;
        margin: 0 $grid-gutter-height/2 $grid-gutter-height 0;
        padding: $grid-gutter-height/2 $grid-gutter-height;
        line-height: 1.3;
        border-radius: $grid-gutter-height/2;
        font-weight: $font-weight-bold;
        text-decoration: none;
        @include hover-focus {
          text-decoration: none;
        }
      }
    }
    .detail-tiles {
      .detail-tile {
        @include last-child-zero-margin;
        line-height: 1.5;
        font-size: $font-size-base;
        border: 1px solid $gray5;
        border-radius: $border-radius-extra/3;
        padding: $grid-gutter-height*1.5 $grid-gutter-width;
        margin-bottom: $grid-gutter-height;
        p,ol,ul {
          color: $gray;
        }
        h3 {
          font: $font-weight-bold $font-size-lg $font-family-sans-serif;
          margin-bottom: .5em;
        }
        &+p.cta-link {
          margin-top: $grid-gutter-height*2;
        }
      }
    }
    p.cta-link {
      padding-left: $grid-gutter-width;
      a {
        @extend .h5;
        letter-spacing: .04em;
        text-decoration: none;
        color: $body-color;
        border-bottom: 2px solid $body-color;
        padding-bottom: 5px;
        svg,img {
          width: $grid-gutter-width*.9;
          margin-left: $grid-gutter-width*.6;
          transition: all .1s linear;
          vertical-align: middle;
        }
        svg {
          display: none;
        }
        @include hover-focus {
          svg,img {
            margin-left: $grid-gutter-width*.75;
            transition: margin .15s linear,transform .1s linear;
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
.cta-block {
  p.cta-link {
    a {
      @extend .h5;
      letter-spacing: .04em;
      text-decoration: none;
      color: $body-color;
      border-bottom: 2px solid $body-color;
      padding-bottom: 5px;
      svg,img {
        width: $grid-gutter-width*.9;
        margin-left: $grid-gutter-width*.6;
        transition: all .1s linear;
        vertical-align: middle;
      }
      svg {
        display: none;
      }
      @include hover-focus {
        svg,img {
          margin-left: $grid-gutter-width*.75;
          transition: margin .15s linear,transform .1s linear;
          transform: scale(1.1);
        }
      }
    }
  }
  article {
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
    padding: 24px;
    column-gap: 32px;
    @include media-breakpoint-up(md) {
      align-items: center;
    }
    figure {
      margin-bottom: 24px;
      @include media-breakpoint-down(sm) {
        margin-right: auto;
      }
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
    .details {
      font-size: $font-size-base;
      flex: 1;
      :last-child {
        margin-bottom: 0;
      }
      h3 {
        font-size: $font-size-h6;
        margin-top: 0;
        margin-bottom: 8px;
      }
    }
    &.cta-border {
      background-color: $white;
      border: 1px solid $gray-lightest;
      border-radius: $border-radius-cta;
    }
  }
  .cta-icon {
    article {
      padding: $border-radius-cta*2;
      figure {
        width: $border-radius-cta*4;
        height: $border-radius-cta*4;
        border-radius: $border-radius-cta*2;
        background-color: $purple-light;
        padding: $border-radius-cta;
        img {
          width: $border-radius-cta*2;
        }
      }
      @include media-breakpoint-down(sm) {
        .action {
          margin-top: 24px;
          margin-right: auto;
        }
      }
    }
  }
  .cta-img {
    article {
      figure {
        max-width: 128px;
        img {
          border-radius: $border-radius-cta/2;
        }
      }
      .details {
        p {
          margin-bottom: $grid-gutter-height;
        }
      }
    }
  }
  .cta-img-lg {
    article {
      padding: 56px;
      flex-direction: column;
      border-radius: $border-radius-cta*2;
      @include media-breakpoint-up(md) {
        flex-direction: row-reverse;
        column-gap: 32px;
      }
      @include media-breakpoint-up(lg) {
        column-gap: 80px;
      }
      @include media-breakpoint-up(xl) {
        column-gap: 160px;
      }
      figure {
        max-width: 300px;
        @include media-breakpoint-up(xl) {
          max-width: 400px;
        }
        img {
          border-radius: $border-radius-cta;
        }
      }
      .details {
        h3 {
          font-size: $font-size-h3;
          margin-bottom: 16px;
        }
      }
    }
  }
}
.customizable-feature {
  @include block-margin-vert;
  .media {
    figure {
      @include media-breakpoint-up(lg) {
        padding: 10px;
      }
    }
  }
  .details {
    font-size: $font-size-base;
    h3 {
      margin-bottom: $grid-gutter-height;
    }
    p,ul,ol {
      margin-bottom: $grid-gutter-height*1.5;
    }
    p.cta-link {
      padding-left: 0;
    }
/*
    .detail-tiles {
      width: 90%;
      @include media-breakpoint-up(md) {
        width: 80%;
      }
      @include media-breakpoint-up(lg) {
        width: 70%;
      }
      @include media-breakpoint-up(xl) {
        width: 60%;
      }
    }
*/
  }
}
/*
.customizable-feature {
  .media {
    @include media-breakpoint-down(md) {
      padding: 0 $grid-gutter-width/2;
    }
  }
  .details {
    .detail-tiles {
      @include media-breakpoint-up(md) {
        width: 80%;
      }
      @include media-breakpoint-up(lg) {
        width: 70%;
      }
      @include media-breakpoint-up(xl) {
        width: 60%;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &>.row {
      flex-direction: row;
      .media {
        @include make-col-offset(0);
        figure {
          margin-right: 0;
          margin-left: -330px;
          text-align: right;
          img,lottie-player {
            margin-left: auto;
          }
        }
      }
      .details {
        @include make-col-offset(1);
        .details-wrap {
          margin-right: -330px;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    &>.row {
      .media {
        figure {
          margin-left: -108px;
        }
      }
      .details {
        .details-wrap {
          margin-right: -108px;
        }
      }
    }
  }
}
*/
.patient-journey {
  text-align: center;
  .titles {
    figure {
      margin: 0 auto $grid-gutter-height;
      padding: 12px;
      text-align: center;
      background: radial-gradient(50% 50% at 50% 50%, $purple 0%, rgba($purple, 0.5) 59.9%, rgba($purple, 0) 100%);
      width: $grid-gutter-height*6;
      @include media-breakpoint-up(lg) {
        margin-bottom: $grid-gutter-height*1.5;
      }
      img {
        border-radius: 50%;
        border-width: 4px;
        border-style: solid;
      }
    }
  }
  .videos {
    video {
      width: 100%;
      max-height: 100%;
    }
  }
}
.block-client-logos {
  padding: $grid-gutter-height*2 0;
  text-align: center;
  h2 {
    @extend h4;
    margin-bottom: 2em;
  }
  span.client-logo {
    display: inline-block;
    margin: 0 $grid-gutter-width $grid-gutter-width*2;
    @include media-breakpoint-up(lg) {
      margin: 0 $grid-gutter-width*1.5 $grid-gutter-width*2;
    }
    img {
      max-width: 200px;
      max-height: 54px;
      width: auto;
      height: auto;
    }
  }
  .social-proof-block {
    justify-content: center;
    margin-top: 0;
  }
}
.testimonials-block {
//   margin: $grid-gutter-width 0 $grid-gutter-width*4;
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width*4;
  @include media-breakpoint-up(lg) {
    margin-top: $grid-gutter-width*2;
  }
  .testimonials-carousel {
    visibility: hidden;
    &.slick-initialized {
      visibility: visible;
    }

  }
  .avatar {
    text-align: center;
    margin-bottom: $grid-gutter-height;
    @include media-breakpoint-up(lg) {
      text-align: right;
      margin-bottom: 0;
      margin-top: $grid-gutter-height*1.5;
    }
    img {
//       max-width: 112px;
      border-radius: 50%;
      display: inline-block;
    }
  }
  article {
    border: 1px solid $gray5;
    border-radius: $border-radius*2;
    padding: $grid-gutter-height;
    @include media-breakpoint-up(sm) {
      padding: $grid-gutter-height*1.5;
    }
    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-height*2;
    }
    .quote {
      @include last-child-zero-margin;
      font-size: $font-size-lg2;
      font-style: italic;
      line-height: 1.4;
    }
    .attribution {
      margin-top: 1em;
      align-items: center;
      span {
        display: block;
        line-height: 1.2;
        &.name {
          font-weight: $font-weight-bold;
          line-height: 1.5;
        }
        &.job-title {
          font-size: $font-size-sm;
        }
      }
      .company-logo {
        figure {
          margin-bottom: 0;
          text-align: right;
          max-width: 225px;
          @include media-breakpoint-up(md) {
            margin-left: auto;
          }
          img {
            display: inline-block;
          }
        }
        margin-top: $grid-gutter-height*1.5;
        @include media-breakpoint-up(sm) {
          margin-top: 0;
          text-align: right;
        }
      }
    }
  }
}
