.faq-actions,.job-actions {
  text-align: right;
  font-size: $font-size-sm;
  margin-bottom: $grid-gutter-width*1.5;
  a {
    color: $gray2;
    text-decoration: none;
    @include hover-focus {
      color: $link-color;
    }
    &.close-faqs,&.close-jobs {
      display: none;
    }
  }
}
.accordion {
  border: $border-standard;
  border-radius: $border-radius;
  .card {
    border-top: $border-standard;
    &:first-child {
      border-top: none;
    }
    .card-header {
      padding: 0;
      *[type=button] {
        display: block;
        padding: $grid-gutter-width*1.5 $grid-gutter-width*2.25;
        -webkit-appearance: none;
        border: none;
        background-color: transparent;
        font: $font-size-lg $font-family-sans-title;
        color: $navy;
        text-decoration: none;
        img,svg {
          float: right;
          margin-left: $grid-gutter-width*1.5;
          width: $grid-gutter-width*.8;
          height: auto;
          transition: all 0.15s linear;
          transform: rotateZ(45deg);
          path {
            stroke: $navy;
          }
        }
        &.collapsed {
          img,svg {
            transform: rotateZ(0deg);
            path {
              stroke: $blue;
            }
          }
        }
        svg {
          display: none;
        }
        @include hover-focus {
          color: $link-color;
        }
      }
    }
    .card-body {
      padding: 0 $grid-gutter-width*2.25 $grid-gutter-width*1.5 $grid-gutter-width*2.25;
    }
  }
}
// SVG v PNG
html.svg {
  .accordion .card .card-header *[type=button] {
    svg {
      display: inline-block;
    }
    img {
      display: none;
    }
  }
}
