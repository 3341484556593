// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.form-control {
  font: $input-font-weight $input-font-size $input-font-family;
  min-height: $input-height;
  &:focus {
		&::-webkit-input-placeholder {
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder {
		  color: transparent;
		}
	}
	&.form-control-dark {
  	background-color: $input-bg-alt;
  	border-color: transparent;
    &:focus {
      background-color: $input-bg;
//     	border-color: $input-border-focus;
    }
	}
}
.form-group {
  label {
    font-size: $font-size-sm;
    margin: 0 0 .5em .5em;
    line-height: 1;
  }
}
.gform_wrapper {
  margin: 0 !important;
  .gfield_required {
    @extend .sr-only;
    color: $red !important;
  }
  div.validation_error {
    border: none !important;
    border-radius: $border-radius;
//     color: $white !important;
    font-weight: $font-weight-base !important;
    margin-bottom: $grid-gutter-height*1.5 !important;
    padding: $grid-gutter-height !important;
    text-align: left !important;
//     background-color: $red;
    &>form {
      margin-top: 1rem;
    }
  }
  &.gform_validation_error {
    .gform_body {
      ul {
        li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
          max-width: none !important;
          min-width: 100% !important;
          &#field_1_2,&#field_2_2,&#field_1_3 {
            .contact &,.schedule-demo & {
//               margin-top: $grid-gutter-height !important;
              @media only screen and (min-width: 641px) {
                min-width: 0 !important;
              }
            }
          }
        }
      }
    }
  }
  ul.gform_fields {
    li.gfield {
      padding-right: 0 !important;
      padding-bottom: $grid-gutter-height !important;
      padding-top: 0 !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      &.gfield_error,
      &.gfield_contains_required.gfield_creditcard_warning {
        background-color: transparent !important;
        margin-bottom: 0 !important;
        border: none !important;
        input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),textarea,select {
          border-color: $red !important;
          background-color: $red-light !important;
        }
        .validation_message {
          @extend .sr-only;
        }
        &+li.gfield.gfield_error,&+li.gfield {
          margin-top: 0 !important;
        }
        &+li.gfield {
//           padding-top: $grid-gutter-height !important;
        }
        label.gfield_label {
          margin-top: 0 !important;
          color: $red;
        }
        .ginput_container.ginput_complex.ginput_container {
          label {
            color: $red !important;
          }
        }
      }
      label.gfield_label {
        font-size: $font-size-sm;
        margin: 0 0 .5em .5em;
        line-height: 1;
        color: $gray1;
        font-weight: $font-weight-base;
        &.gfield_label_before_complex {
          @extend .sr-only;
        }
        &.active {
          color: $blue;
        }
      }
      .ginput_container {
        margin-top: 0 !important;
        &.ginput_complex.ginput_container {
          &.gf_name_has_1,&.gf_name_has_2,&.gf_name_has_3 {
            @media only screen and (min-width: 641px) {
              width: calc(100% + 20px) !important;
            }
          }
          label {
            font-size: $font-size-sm !important;
            margin: 0 0 .5em .5em !important;
            line-height: 1 !important;
            color: $gray1 !important;
            font-weight: $font-weight-base !important;
            letter-spacing: normal !important;
            &.active {
              color: $blue !important;
            }
          }
          input[type=text] {
            margin-bottom: 0 !important;
          }
        }
        &.ginput_container_name {
          span {
            @media only screen and (min-width: 641px) {
              padding-right: $grid-gutter-width;
            }
          }
        }
      }
      span.name_first,span.name_last,span.name_middle {
        padding-top: 0 !important;
        margin-bottom: 0;
/*
        padding-top: 16px !important;
        @media only screen and (min-width: 641px) {
          padding-top: 0 !important;
        }
*/
      }
      span.name_last {

      }
      .contact &,.schedule-demo &  {
        &#field_1_2,&#field_1_3,
        &#field_2_2,&#field_2_3 {
          @media only screen and (min-width: 641px) {
            width: 209px;
            float: left;
            clear: none;
            margin-bottom: $grid-gutter-height;
          }
          @include media-breakpoint-up(md) {
            width: 215px;
          }
          @include media-breakpoint-up(lg) {
            width: 155px;
          }
          @include media-breakpoint-up(xl) {
            width: 183px;
          }
        }
        &#field_1_3,&#field_2_3 {
          @media only screen and (min-width: 641px) {
            margin-left: $grid-gutter-width !important;
          }
        }
      }
    }
  }
  input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),textarea,select {
    @extend .form-control;
    padding-left: $grid-gutter-width*.75 !important;
    padding-right: $grid-gutter-width*.75 !important;
  }
  select {
    -webkit-appearance: none;
    background-image: url(../images/select-arrows.png);
    background-repeat: no-repeat;
    background-position: 96% 50%;
    background-size: auto $grid-gutter-height*1.5;
  }
  textarea {
    height: 120px !important;
  }
}

.gform_wrapper .gform_footer,.gform_wrapper .gform_page_footer,.gform_footer {
  padding: 0 !important;
  input.button,input[type=submit] {
    @extend .btn;
    margin: 0 !important;
    width: 100% !important;
    font: $btn-font-weight $btn-font-size $btn-font-family !important;
    .contact & {
      @extend .btn-primary;
    }
  }
}
.gform_wrapper + .gform_footer {
  margin-top: $grid-gutter-height;
}
.gform_wrapper {
  div.validation_error {
    padding: 0 !important;
    margin-top: 0 !important;
    color: $red !important;
    background-color: transparent !important;
  }
}
.form-wrapper {
  .contact &{
    background-color: $white-near;
    border: $border-standard-dark;
    border-radius: $border-radius;
    padding: $grid-gutter-height*2 $grid-gutter-width*2;
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-height*2 $grid-gutter-height*4;
    }
    @include media-breakpoint-up(xl) {
      padding: $grid-gutter-height*4 $grid-gutter-height*6;
    }
  }
  .schedule-demo & {
    @extend .shadow-box;
    background-color: $white;
    border-radius: $border-radius;
    padding: $grid-gutter-height*2 $grid-gutter-width*2;
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-height*2 $grid-gutter-height*4;
    }
    @include media-breakpoint-up(xl) {
      padding: $grid-gutter-height*4 $grid-gutter-height*6;
    }
  }
}
.content-info {
  .action {
    .form-wrapper {
      border: 1px solid rgba($white,.4);
      border-radius: $border-radius-extra/2;
      padding: $grid-gutter-height*1.5;

      @include media-breakpoint-up(lg) {
        padding: $grid-gutter-height*3;
        padding-bottom: $grid-gutter-height*1.5;
        border-radius: $border-radius-extra;
      }
      p.schedule-link {
        text-align: center;
        font-size: $font-size-xs;
        line-height: 1.3;
        margin: $grid-gutter-height*1.5 0 0;
        a {
          color: $white;
          text-decoration: none;
          @include hover-focus {
            color: rgba($white,.75);
          }
        }
      }
      .gform_wrapper {
        input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
        textarea,select {
          @extend .form-control-dark;
        }
        ul.gform_fields {
          li.gfield {
            &.gfield_error,
            &.gfield_contains_required.gfield_creditcard_warning {
              input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
              textarea {
                border-color: $red !important;
                background-color: $red-light !important;
              }
              label.gfield_label {
                color: $red !important;
              }
              .ginput_container.ginput_complex.ginput_container {
                label {
                  color: $red !important;
                }
              }
            }
            label.gfield_label {
              color: $gray4 !important;
              &.active {
                color: $blue !important;
              }
            }
            .ginput_container {
              &.ginput_complex.ginput_container {
                label {
                  color: $gray4 !important;
                  &.active {
                    color: $blue !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#gform_wrapper_4 {
  form {
    display: flex;
    input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]):not([type=submit]) {
      min-height: 0 !important;
      padding: 8px 8px 8px 16px !important;
      border-color: transparent !important;
      background-color: transparent !important;
      &:not(:focus) {
        &::-webkit-input-placeholder {
    		  color: $gray2;
    		}
    		&:-moz-placeholder { /* Firefox 18- */
    		  color: $gray2;
    		}
    		&::-moz-placeholder {  /* Firefox 19+ */
    		  color: $gray2;
    		}
    		&:-ms-input-placeholder {
    		  color: $gray2;
    		}
      }
    }
  }
  .gform_heading {
    .gform_required_legend {
      @extend .sr-only;
    }
  }
  .gform_body {
    display: inline-block !important;
    width: 100% !important;
    margin-right: $grid-gutter-width*.5 !important;
    ul.gform_fields {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        background-image: none;
        padding-left: 0 !important;
        input {
          margin-top: 0 !important;
          font-size: $font-size-sm !important;

        }
      }
    }
  }
  .gform_footer {
    margin-top: 0 !important;
    display: inline-block !important;
    input.button,input[type=submit] {
      @extend .btn-default-dark;
      border-radius: 8px;
    }
  }
}
#gform_wrapper_10,#gform_wrapper_11 {
  border: 1px solid $gray5;
  border-radius: $border-radius-cta;
  padding: $grid-gutter-height $grid-gutter-height*1.5;
  @include media-breakpoint-up(md) {
    padding: $grid-gutter-height*1.5 $grid-gutter-height*2;
  }
  @include media-breakpoint-up(lg) {
    padding: $grid-gutter-height*2 $grid-gutter-height*3;
  }
  .validation_error {
    padding: 0 !important;
  }
  ul.gform_fields {
    li.gfield {
      hr {
        margin-bottom: 0;
      }
    }
  }
  .gform_footer {
    input.button,input[type=submit] {
      @extend .btn-default-dark;
    }
  }
}
