.feature-tile-wrap {
  display: flex;
  .feature-tile {
    &>.row {
      margin-left: 0;
      margin-right: 0;
    }
    @extend .shadow-box;
    margin-bottom: $grid-gutter-width*2.5;
    padding-top: $grid-gutter-width*2.5;
    @include media-breakpoint-up(md) {
      padding-top: $grid-gutter-width*3.25;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: $grid-gutter-width*5;
      padding-top: $grid-gutter-width*4;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: $grid-gutter-width*5;
      padding-top: $grid-gutter-width*5;
    }

    .details {
      align-self: center;
      padding-bottom: $grid-gutter-width*2.5;
      .h5 {
        @include media-breakpoint-down(sm) {
          padding-left: $grid-gutter-width*.5;
        }
      }
      h2 {
        margin-bottom: $grid-gutter-width;
      }
      .description {
        margin-bottom: $grid-gutter-width;
        color: #7F8BA2;
      }
      @include media-breakpoint-up(md) {
        padding-bottom: $grid-gutter-width*1.25;
        h2 {
          margin-left: -$grid-gutter-width;
        }
      }
      @include media-breakpoint-up(lg) {
        padding-bottom: $grid-gutter-width*3;
        .h5 {
          margin-bottom: $grid-gutter-width*1.5;
        }
        h2 {
          margin-bottom: $grid-gutter-width*1.5;
        }
        .description {
          margin-bottom: $grid-gutter-width*2.5;
        }
      }
      @include media-breakpoint-up(xl) {
      }
    }
    .ui-example {
      display: flex;
      @include media-breakpoint-only(lg) {
        padding-right: $grid-gutter-width;
      }
      .phone-notification {
        align-self: flex-end;
        position: relative;
        flex: 1;
        .phone {
          @extend .img-fluid;
        }
        .phone-dialogue-wrap {
          position: absolute;
          top: 65px;
          width: 110%;
          left: -$grid-gutter-width*2.5;
        }
        .notification-wrap {
          @extend .shadow-box-low;
          background-image: url(../images/ui/notification-logo.png);
          background-position: $grid-gutter-width*.5 $grid-gutter-width*.6;
          background-repeat: no-repeat;
          background-size: $grid-gutter-width*1.7 auto;
/*
          position: absolute;
          top: 100px;
          width: 110%;
          left: -$grid-gutter-width*2.5;
*/
          border: 1px solid $gray4;
          padding: $grid-gutter-width $grid-gutter-width*.6 $grid-gutter-width*1.2 $grid-gutter-width*2.5;
          letter-spacing: .02em;
          header {
            color: $gray3;
            font: $font-weight-med $font-size-h5 $font-family-sans-ui;
            margin-bottom: $grid-gutter-width*.5;
            time {
              float: right;
            }
          }
          .notification {
            font: $font-weight-base $font-size-sm $font-family-sans-ui;
            letter-spacing: .02em;
            line-height: 1.25;
            padding-right: $grid-gutter-width/2;
          }
        }
        .video-wrap {
          @extend .shadow-box;
          margin: $grid-gutter-width*1.25 (-$grid-gutter-width*2.5) 0 0;
          position: relative;
          width: 82%;
          float: right;
          min-height: $grid-gutter-width*5;
          @include media-breakpoint-only(md) {
//             width: 70%;
          }
          @include media-breakpoint-only(lg) {
            margin-right: -$grid-gutter-width*1.5;
          }
          .video-name {
            position: absolute;
            top: 6px;
            right: 6px;
            border-radius: 2px;
            background-color: rgba($navy,.4);
            padding: 2px 6px;
            line-height: 1;
            color: $white;
            font-size: $font-size-xxxs;
          }
          .video-icon {
            position: absolute;
            left: $grid-gutter-width*.8;
            bottom: $grid-gutter-width*.4;
            width: $grid-gutter-width*2.4;
            height: auto;
          }
          .video-screen {
            @extend .img-fluid;
            border-radius: 6px;
          }
        }
      }
      .chat-window {
        font: $font-weight-med $font-size-ui-fine $font-family-sans-ui;
        color: $gray3;
        align-self: center;
        position: relative;
        width: 100%;
        letter-spacing: .02em;
        padding-bottom: $grid-gutter-width;
        @include media-breakpoint-up(lg) {
//           padding-bottom: $grid-gutter-width*2;
        }
        @include media-breakpoint-up(xl) {
          padding-bottom: $grid-gutter-width*4;
        }
        p.date {
          text-align: center;
        }
        .chat-turn {
          margin-bottom: $grid-gutter-width;
          position: relative;
          .name {
            margin: 0 0 $grid-gutter-width*.2 $grid-gutter-width*.75;
          }
          .time {
            margin: -2px 0 0 $grid-gutter-width*.75;
            font-weight: $font-weight-base;
          }
          .avatar {
            width: $grid-gutter-width*2;
            height: $grid-gutter-width*2;
            border-radius: $grid-gutter-width;
            position: absolute;
            bottom: $grid-gutter-width*.25;
            left: -$grid-gutter-width*1.5;
          }
          .bubble {
            padding: $grid-gutter-width*.75 $grid-gutter-width*.75;
            margin-bottom: $grid-gutter-width*.3;
            font-weight: $font-weight-base;
            font-size: $font-size-sm;
            color: $body-color;
            background-color: $gray5;
            border-radius: $border-radius;
          }
           &.pov-you {
              margin-left: $grid-gutter-width*2;
              margin-right: -$grid-gutter-width*2;
              @include media-breakpoint-only(lg) {
                margin-left: $grid-gutter-width;
                margin-right: -$grid-gutter-width;
              }
              .name {
                display: none;
              }
              .bubble {
                background-color: $blue;
                color: $white;
             }
           }
        }
      }
    }
  }
}
.ehr-integrations {
  margin-top: 0;
/*
  padding: 0 0 $grid-gutter-width*4;
  @include media-breakpoint-up(xl) {
    padding: $grid-gutter-width*2 0 $grid-gutter-width*5.5 0;
  }
*/
  .intro {
    p {
      color: $gray1;
    }
    @include media-breakpoint-up(md) {
      text-align: center;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: $grid-gutter-width;
    }
    @include media-breakpoint-up(xl) {
      padding-bottom: $grid-gutter-width*2;
    }
  }
  .client-logos {
    text-align: center;
    span {
      display: inline-block;
      margin: 0 $grid-gutter-width $grid-gutter-width*2;
      @include media-breakpoint-up(lg) {
        margin: 0 $grid-gutter-width*1.5 $grid-gutter-width*2;
      }
      img {
        max-width: 170px;
        max-height: 38px;
        width: auto;
        height: auto;
      }
      &.client-logo {
        @include media-breakpoint-down(xs) {
          display: block;
        }
      }
      &.ehr-numbers {
        border-radius: $border-radius;
        background-color: $gray6;
        color: $gray1;
        font-weight: $font-weight-bold;
        padding: 8px 16px;
        line-height: 1;
      }
    }
  }
  &+.text-cta {
    margin-top: -$grid-gutter-height*2;
    margin-bottom: $grid-gutter-height*4;
  }
}
.text-cta {
  .cta-wrap {
    border-top: 1px solid $gray5;
    border-bottom: 1px solid $gray5;
    padding: $grid-gutter-height*2 0;
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width*2 0;
    }
  }
  article {
    @include last-child-zero-margin;
    padding: $grid-gutter-height*1.5;
    padding-left: $grid-gutter-height*4.5;
    font-size: $font-size-lg;
    color: $blue-dark;
    background-size: $grid-gutter-height*2 auto;
    background-position: $grid-gutter-height*1.5 $grid-gutter-height*1.5;
    background-repeat: no-repeat;
    border-radius: $border-radius;
  }
}
.experience {
//   @extend .gray-gradient;
  overflow: hidden;
  padding: 0 0 $grid-gutter-width*4;
  @include media-breakpoint-up(xl) {
    padding: $grid-gutter-width*2 0 $grid-gutter-width*5 0;
  }
  .intro {
    p {
      color: $gray1;
    }
    @include media-breakpoint-up(md) {
      text-align: center;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: $grid-gutter-width;
    }
    @include media-breakpoint-up(xl) {
      padding-bottom: $grid-gutter-width*2;
    }
  }
  .ui-table {
    @extend .shadow-box-low;
    position: relative;
    font: $font-size-sm $font-family-sans-ui;
    padding: $grid-gutter-width*2;
    text-align: left;
    margin-top: $grid-gutter-width;
    @include media-breakpoint-up(xl) {
      margin: 0 $grid-gutter-width*2 ;
      width: 100%;
    }
    h3 {
      line-height: $grid-gutter-width*3;
      font-family: $font-family-sans-ui;
      font-weight: $font-weight-med;
      img {
        width: $grid-gutter-width*3;
        float: left;
        margin-right: $grid-gutter-width*1.25;
      }
    }
  }
  .push-wrap {
    position: relative;
    .push-notification {
      @extend .shadow-box;
      min-height: $grid-gutter-width*6;
      line-height: 1.3;
      width: 100%;
      margin-top: -$grid-gutter-width*1.25;
      padding: $grid-gutter-width*1.25;
      padding-left:  $grid-gutter-width*5;
      @include media-breakpoint-up(sm) {
        padding: $grid-gutter-width*2.4 $grid-gutter-width*2 $grid-gutter-width*2.4 $grid-gutter-width*6.5;
      }
      @include media-breakpoint-only(md) {
        max-width: 550px;
      }
      @include media-breakpoint-up(lg) {
        margin-top: -$grid-gutter-width*2.5;
      }
      @include media-breakpoint-up(xl) {
/*
        margin-top: 0;
        position: absolute;
        width: 100%;
        left: 0;
        width: 550px;
        right: -$grid-gutter-width*2;
        bottom: -$grid-gutter-width*3.5;
*/
      }
      b {
        color: $brand-primary;
      }
      img {
        position: absolute;
        width: $grid-gutter-width*3;
        left: $grid-gutter-width*.75;
        top: $grid-gutter-width*1.25;
        @include media-breakpoint-up(sm) {
          left: $grid-gutter-width*2;
          top: $grid-gutter-width*2.4;
        }
      }
    }
  }
  .cta {
    padding-top: $grid-gutter-width*2.5;
    color: $gray1;
    text-align: left;
    @include media-breakpoint-only(md) {
      padding-top: $grid-gutter-width*3;
    }
    @include media-breakpoint-up(xl) {
      padding-top: $grid-gutter-width*1.5;
    }
  }
  .features {
    justify-content: center;
    .feature {
      text-align: left;
      color: $gray1;
      p {
        &.feature-name {
          font-weight: $font-weight-bold;
          color: $body-color;
        }
      }
    }
  }
}
