// COLORS :: name, color, hover, light, dark
$color-list: (
  blue        $blue         $white        $blue-light       $blue-dark,
  green       $green        $white        $green-light      $green-dark,
  navy        $navy         $white        $navy-light2      $navy,
  orange      $orange       $foundation   $orange-light     $orange-dark,
  purple      $purple       $white        $purple-light     $purple-dark,
  purple-dark $purple-dark  $white        $purple           $purple-dark,
  red         $red          $white        $red-light        $red-dark,
  teal        $teal         $navy         $teal             $teal,
  yellow      $yellow       $navy         $yellow-light     $yellow-dark,
  foundation  $foundation   $white        $foundation-light $foundation,
  gray1       $gray1        $white        $gray1-light      $gray1,
  gray2       $gray2        $white        $gray2            $gray2,
  gray3       $gray3        $white        $gray3            $gray3,
  gray4       $gray4        $foundation   $gray4            $gray4,
  gray5       $gray5        $foundation   $gray5            $gray5,
  gray6       $gray6        $foundation   $gray6            $gray6
);
@each $value in $color-list {
  .link-#{nth($value, 1)},.link-#{nth($value, 1)} a {
    color: #{nth($value, 2)} !important;
    @include hover-focus {
      color: #{nth($value, 3)} !important;
    }
  }
  .text-#{nth($value, 1)} {
    color: #{nth($value, 2)} !important;
  }
  .bg-#{nth($value, 1)} {
    background-color: #{nth($value, 2)};
    color: #{nth($value, 3)};
  }
  .bg-#{nth($value, 1)}-light {
    background-color: #{nth($value, 4)};
  }
  .border-#{nth($value, 1)} {
    border: $border-width solid #{nth($value, 2)};
  }
  .border-hover-#{nth($value, 1)} {
    @include hover-focus {
      border: $border-width solid #{nth($value, 2)};
    }
  }
  .brick-#{nth($value, 1)} {
    @include hover-focus {
      border-color: #{nth($value, 2)};
      background-color: #{nth($value, 4)};
    }
  }
  .feature-link-#{nth($value, 1)} {
    border: 1px solid transparent;
    background-color: #{nth($value, 4)};
    color: #{nth($value, 5)};
    @include hover-focus {
      border-color: #{nth($value, 5)};
      color: #{nth($value, 5)};
    }
    &.active {
      border-color: #{nth($value, 5)};
      color: #{nth($value, 5)};
    }
  }
  .detail-tile-#{nth($value, 1)} {
    border-color: #{nth($value, 5)} !important;
    h3 {
      color: #{nth($value, 5)};
    }
  }
  .feature-#{nth($value, 1)} {
    h3 {
      color: #{nth($value, 5)};
    }
    mark {
      color: #{nth($value, 5)};
      background-color: #{nth($value, 4)};
    }
  }
  .action-#{nth($value, 1)} {
    @include hover-focus {
      color: #{nth($value, 2)} !important;
    }
    span {
      border-bottom-color: #{nth($value, 2)};
    }
    svg path {
      stroke: #{nth($value, 2)};
    }
  }
  .image-halo-#{nth($value, 1)} {
    border-color: #{nth($value, 2)};
  }
  .plan-#{nth($value, 1)} {
    a {
      color: #{nth($value, 2)};
    }
    .plan-details {
      ul {
        li {
          background-image: url(../images/bullet-check-#{nth($value, 1)}.svg) !important;
        }
      }
    }
  }
}
a[class*="action-"] {
  @extend .h5;
  letter-spacing: .04em;
  text-decoration: none;
  span {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding: 5px 1px;
  }
  svg,img {
    width: $grid-gutter-width*.9;
    margin-left: $grid-gutter-width*.6;
    transition: all .1s linear;
    vertical-align: middle;
  }
  svg {
    display: none;
  }
  @include hover-focus {
    svg,img {
      margin-left: $grid-gutter-width*.75;
      transition: margin .15s linear,transform .1s linear;
      transform: scale(1.1);
    }
  }
}
span.action-read {
  @extend .h5;
  letter-spacing: .04em;
  text-decoration: none;
  color: $body-color;
  span {
    border-bottom: 2px solid $blue;
    padding: 5px 1px;
  }
  svg,img {
    width: $grid-gutter-width*.9;
    margin-left: $grid-gutter-width*.6;
    transition: all .1s linear;
    vertical-align: middle;
  }
  svg {
    display: none;
    path {
      stroke: $blue;
    }
  }
}
.icon-circle {
  width: $grid-gutter-width*3;
  height: $grid-gutter-width*3;
  border-radius: $grid-gutter-width*1.5;
  margin-bottom: $grid-gutter-width*2;
  text-align: center;
  position: relative;
  svg,img {
    max-width: $grid-gutter-width*1.4;
    max-width: $grid-gutter-width*1.4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    path {
      fill: $white;
    }
  }
  svg {
    display: none;
  }
}
.return-to-list {
  svg {
    display: none;
  }
}
// SVG v PNG
html.svg {
  a[class*="action-"],.icon-circle,.card-header a,span.action-read,.return-to-list {
    svg {
      display: inline-block;
    }
    img {
      display: none;
    }
  }
}


.shadow-box {
  background-color: $white;
//   border: 1px solid $gray4;
  border-radius: $border-radius;
  box-shadow: 0px 50px 50px rgba(71, 78, 94, 0.05), 0px 10px 40px rgba(71, 78, 94, 0.1);
}
.shadow-box-low {
  background-color: $white;
//   border: 1px solid $gray4;
  border-radius: $border-radius;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.shadow-box-btn {
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.tile-box {
  background-color: $white;
  border: $border-standard;
  border-radius: $border-radius;
  .entry-title {
    @include transition($link-transition-color);
  }
  @include hover-focus {
    @extend .shadow-box-low;
    .entry-title {
      color: $link-color;
    }
  }
}
.tile-box-shadowless {
  background-color: transparent;
  border: $border-standard;
  border-color: transparent;
  border-radius: $border-radius;
  .entry-title {
    @include transition($link-transition-color);
  }
  @include hover-focus {
    border-color: $gray4;
    .entry-title {
      color: $link-color;
    }
    .action-read {
      @include transition($link-transition-color);
      color: $link-color;
      svg,img {
        margin-left: $grid-gutter-width*.75;
        transition: margin .15s linear,transform .1s linear;
        transform: scale(1.1);
      }
    }
  }
}
.gray-gradient {
  background: linear-gradient(180deg, rgba(231, 233, 237, 0.0) 0%, #E7E9ED 100%);
}

.nav-tag-highlight {
  display: inline-block;
  padding: $grid-gutter-width*.2 $grid-gutter-width*.4;
  font: $font-weight-med $font-size-xxs $headings-font-family;
  line-height: 1;
  margin-left: $grid-gutter-width*.4;
  text-transform: uppercase;
  border-radius: $border-radius-sm;
  vertical-align: middle;
  letter-spacing: .05em;
}
.nav-small-info {
  display: block;
  margin: $grid-gutter-width*.4 0 $grid-gutter-width*.75;
  line-height: 1.4;
  font-size: $font-size-xs;
  color: $gray4;
  @include transition($link-transition-slow);
}
.nav-dark-hover {
  color: $dropdown-link-hover-color;
  text-decoration: none;
  background-color: $dropdown-link-hover-bg;
  small {
    color: $white;
  }
}
.nav-link-header {
  @extend .h5;
  cursor: default;
  margin-bottom: 0 !important; // for use with heading elements
  color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
  @include hover-focus {
    color: $dropdown-header-color;
  }
}
.nav-divider-border {
  border-top: 1px solid $dropdown-divider-bg;
  margin: $grid-gutter-width 0 0;
  padding: $grid-gutter-width 0 $grid-gutter-width/2;
}
.bullet-check {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  list-style: none;
  color: $gray1;
  li {
    background: url(../images/bullet-check.svg) no-repeat left top;
    padding: 0 0 0 $grid-gutter-width*2;
    margin-bottom: $grid-gutter-width;
  }
}
