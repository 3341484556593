body.page-template-template-in-app {
  .page-header-in-app {
    padding: $grid-gutter-width*2.2 0 $grid-gutter-width*1.2;
    border-bottom: 1px solid $gray5;
    .page-header {
      padding-top: 0;
      padding-bottom: 0;
      text-align: center;
      h1 {
        @extend .h6;
        margin-bottom: 0;
      }
    }
  }
  .post-thumbnail-in-app {
    @include media-breakpoint-down(xs) {
      &>.container {
        padding-left: 0;
        padding-right: 0;
        &>.row {
          margin: 0;
          &>.col-12 {
            padding: 0;
          }
        }
      }
    }
    figure {
      margin-bottom: $grid-gutter-width*2;
      @include media-breakpoint-up(sm) {
        margin-top: $grid-gutter-width*1.5;
      }
      @include media-breakpoint-up(md) {
        margin: $grid-gutter-width*2.5 0 $grid-gutter-width*3;
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: $grid-gutter-width*3 0 $grid-gutter-width*3.5;
      }
    }

  }
}

