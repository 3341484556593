// Grid system
.main,.page-header,.related-bricks {
  @include make-col-ready();
  @include media-breakpoint-up(md) {
    @include make-col($main-md-columns);
  }
  .blog &,.archive & {
    @include make-col(12);
    @include make-col-offset(0);
  }
  .page-template-template-reusable & {
    @include make-col(12);
    @include make-col-offset(0);
    @include media-breakpoint-up(lg) {
      @include make-col(10);
      @include make-col-offset(1);
    }
  }
  .page-template-template-news & {
    @include make-col(12);
    @include make-col-offset(0);
  }
  .error404 &,.download & {
    @include make-col(12);
    @include make-col-offset(0);
    @include media-breakpoint-up(md) {
      @include make-col(10);
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(8);
      @include make-col-offset(2);
    }
  }
  .faq &,.page-id-1537 & {
    @include make-col(12);
    @include make-col-offset(0);
    @include media-breakpoint-up(md) {
      @include make-col(10);
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(8);
      @include make-col-offset(2);
    }
  }
  .page-template-template-in-app & {
    @include make-col(12);
    @include make-col-offset(0);
    @include media-breakpoint-up(md) {
      @include make-col(10);
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(8);
      @include make-col-offset(2);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(6);
      @include make-col-offset(3);
    }
  }
  .single-post & {
    @include media-breakpoint-up(sm) {
      @include make-col($main-single-sm-columns);
      @include make-col-offset($main-single-sm-offset);
    }
    @include media-breakpoint-up(md) {
      @include make-col($main-single-md-columns);
      @include make-col-offset($main-single-md-offset);
    }
    @include media-breakpoint-up(lg) {
      @include make-col($main-single-lg-columns);
      @include make-col-offset($main-single-lg-offset);
    }
    @include media-breakpoint-up(xl) {
      @include make-col($main-single-xl-columns);
      @include make-col-offset($main-single-xl-offset);
    }
  }
  .sidebar-primary & {
    @include media-breakpoint-up(md) {
      @include make-col($main-md-columns);
      @include make-col-offset($main-md-offset);
    }
    @include media-breakpoint-up(lg) {
      @include make-col($main-lg-columns);
      @include make-col-offset($main-lg-offset);
    }
    @include media-breakpoint-up(xl) {
      @include make-col($main-xl-columns);
      @include make-col-offset($main-xl-offset);
    }
  }
  .home & {
    @include media-breakpoint-up(md) {
      @include make-col($main-full-md-columns);
      @include make-col-offset($main-full-md-offset);
    }
/*
    @include media-breakpoint-up(lg) {
      @include make-col($main-simple-lg-columns);
      @include make-col-offset($main-simple-lg-offset);
    }
*/
  }
  .page-template-template-simple & {
    @include media-breakpoint-up(md) {
      @include make-col($main-simple-md-columns);
      @include make-col-offset($main-simple-md-offset);
    }
    @include media-breakpoint-up(lg) {
      @include make-col($main-simple-lg-columns);
      @include make-col-offset($main-simple-lg-offset);
    }
  }
  .page-template-template-features & {
    @include media-breakpoint-up(sm) {
      @include make-col($main-feature-sm-columns);
      @include make-col-offset($main-feature-sm-offset);
    }
    @include media-breakpoint-up(md) {
      @include make-col($main-feature-md-columns);
      @include make-col-offset($main-feature-md-offset);
    }
    @include media-breakpoint-up(lg) {
      @include make-col($main-feature-lg-columns);
      @include make-col-offset($main-feature-lg-offset);
    }
  }
}
.single-integration,.single-partner,.page-template-template-ip,.page-template-template-ip-form {
  .page-header {
    @include media-breakpoint-up(sm) {
      @include make-col($main-single-sm-columns);
      @include make-col-offset($main-single-sm-offset);
    }
    @include media-breakpoint-up(md) {
      @include make-col($main-single-sm-columns);
      @include make-col-offset($main-single-sm-offset);
    }
    @include media-breakpoint-up(lg) {
      @include make-col($main-single-sm-columns);
      @include make-col-offset($main-single-sm-offset);
    }
    @include media-breakpoint-up(xl) {
      @include make-col($main-single-sm-columns);
      @include make-col-offset($main-single-sm-offset);
    }
  }
  .main {
    @include media-breakpoint-up(sm) {
      @include make-col($main-single-sm-columns);
      @include make-col-offset($main-single-sm-offset);
    }
    @include media-breakpoint-up(md) {
      @include make-col($main-single-sm-columns);
      @include make-col-offset($main-single-sm-offset);
    }
    @include media-breakpoint-up(lg) {
      @include make-col($main-single-md-columns);
      @include make-col-offset($main-single-md-offset);
    }
    @include media-breakpoint-up(xl) {
      @include make-col($main-single-md-columns);
      @include make-col-offset($main-single-md-offset);
    }
  }
}
.page-template-template-ip {
  .page-header {
    @include media-breakpoint-up(lg) {
      @include make-col(10);
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(8);
      @include make-col-offset(2);
    }
  }
}
.page-template-template-ip-form {
  .main {
    @include media-breakpoint-up(sm) {
      @include make-col(12);
      @include make-col-offset(0);
    }
    @include media-breakpoint-up(md) {
      @include make-col(10);
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(8);
      @include make-col-offset(2);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(6);
      @include make-col-offset(3);
    }
  }
}
.page-template-template-plans {
  .page-header {
    @include make-col($main-sm-columns);
    @include make-col-offset(0);
  }
  .main {
    @include media-breakpoint-up(sm) {
      @include make-col($main-plans-sm-columns);
      @include make-col-offset($main-plans-sm-offset);
    }
    @include media-breakpoint-up(md) {
      @include make-col($main-plans-md-columns);
      @include make-col-offset($main-plans-md-offset);
    }
    @include media-breakpoint-up(lg) {
      @include make-col($main-plans-lg-columns);
      @include make-col-offset($main-plans-lg-offset);
    }
    @include media-breakpoint-up(xl) {
      @include make-col($main-plans-xl-columns);
      @include make-col-offset($main-plans-xl-offset);
//       padding-right: $grid-gutter-width*6;
    }
  }
}
.container {
  @include media-breakpoint-down(xs) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
    @include make-col-offset($sidebar-sm-offset);
  }
  @include media-breakpoint-up(md) {
    @include make-col($sidebar-md-columns);
    @include make-col-offset($sidebar-md-offset);
  }
  @include media-breakpoint-up(lg) {
    @include make-col($sidebar-lg-columns);
    @include make-col-offset($sidebar-lg-offset);
  }
  @include media-breakpoint-up(xl) {
    @include make-col($sidebar-xl-columns);
    @include make-col-offset($sidebar-xl-offset);
  }
  .page-template-template-features & {
    @include media-breakpoint-up(sm) {
      @include make-col($sidebar-feature-sm-columns);
      @include make-col-offset($sidebar-feature-sm-offset);
    }
    @include media-breakpoint-up(md) {
      @include make-col($sidebar-feature-md-columns);
      @include make-col-offset($sidebar-feature-md-offset);
    }
    @include media-breakpoint-up(lg) {
      @include make-col($sidebar-feature-lg-columns);
      @include make-col-offset($sidebar-feature-lg-offset);
    }
  }
  .page-template-template-plans & {
    @include media-breakpoint-up(sm) {
      @include make-col($sidebar-plans-sm-columns);
      @include make-col-offset($sidebar-plans-sm-offset);
    }
    @include media-breakpoint-up(md) {
      @include make-col($sidebar-plans-md-columns);
      @include make-col-offset($sidebar-plans-md-offset);
    }
    @include media-breakpoint-up(lg) {
      @include make-col($sidebar-plans-lg-columns);
      @include make-col-offset($sidebar-plans-lg-offset);
    }
    @include media-breakpoint-up(xl) {
      @include make-col($sidebar-plans-xl-columns);
      @include make-col-offset($sidebar-plans-xl-offset);
    }
  }
}
.page-id-57 {
  .page-header {
    @include make-col(12);
    @include make-col-offset(0);
  }
  .main {
    @include make-col(12);
    @include make-col-offset(0);
    @include media-breakpoint-up(sm) {
      @include make-col(10);
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(md) {
      @include make-col(8);
      @include make-col-offset(2);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(5);
      @include make-col-offset(0);
    }
  }
  .sidebar {
    @include make-col(12);
    @include make-col-offset(0);
    @include media-breakpoint-up(md) {
      @include make-col(10);
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(6);
      @include make-col-offset(1);
    }
  }
}
.schedule-demo,.page-template-template-calendly {
  .main,.page-header,.sidebar {
    @include make-col(12);
    @include make-col-offset(0);
    @include media-breakpoint-up(md) {
      @include make-col(10);
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(6);
      @include make-col-offset(3);
    }
  }
}
.agreements {
  .main,.page-header,.sidebar {
    @include make-col(12);
    @include make-col-offset(0);
    @include media-breakpoint-up(sm) {
      @include make-col(10);
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(md) {
      @include make-col(6);
      @include make-col-offset(3);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(4);
      @include make-col-offset(4);
    }
  }
}

.page-id-1173,.page-id-1429,.page-id-1430,.page-id-1431 {
  .main {
    @include make-col(12);
    @include make-col-offset(0);
    @include media-breakpoint-up(md) {
      @include make-col(10);
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(8);
      @include make-col-offset(2);
    }
  }
}
