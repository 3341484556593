.modal-content {
  background-color: $modal-content-bg;
  color: $modal-content-color;
}

#mobileMenu {
/*
  @include media-breakpoint-up(lg) {
    display: none !important;
  }

*/
  .modal-dialog {
    margin: $grid-gutter-width*.75;
    @include media-breakpoint-up(sm) {
      width: 400px;
      float: right;
    }
  }
  .modal-content {
    padding: $modal-inner-padding;
    position: relative;
    .close {
      position: absolute;
      opacity: 1;
      right: $modal-inner-padding;
      top: $modal-inner-padding;
      img {
        width: $grid-gutter-width*.8;
      }
      &:active {
        outline:none;
      }
      @include hover-focus {
        outline:none;
        opacity: .8;
      }

    }
  }
}
.modal-backdrop {
  background-color: $navy;
}
.modal.ekko-lightbox {
  .modal-body {
    padding: 8px;
  }
}
