// Grid settings - basic
$grid-gutter-width-base:  20px;
$grid-gutter-width:       $grid-gutter-width-base;
$grid-gutter-height:      16px;

/*
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;
*/


// Colors
$blue:        #0094FF;
$blue-light:  #EBF6FF;
$blue-dark:   #00477A;
$green:       #00C083;
$green-light: #EFFBF7;
$green-dark:  #005C3F;
$navy:        #001747;
$navy-light:  #183062;
$navy-light2: rgba($navy-light,.05);
$orange:      #ED9829;
$orange-light:#FDF4E8;
$orange-dark: #9F5C04;
$red:         #ED5956;
$red-light:   #FEF6F6;
$red-dark:    #8A110F;
$purple:      #6767E5;
$purple-light:#F6F6FE;
$purple-dark: #2222C4;
$teal:        #7FDFC0;
$yellow:      #FFDA66;
$yellow-light:#FCF6E3;
$yellow-dark: #8B6A00;

$black:       #000;
$white:       #fff;
// $white-near:  #FCFDFF;
$white-near:  #FDFDFD;
$white-dark:  #FAFAFB;
$foundation:  #222F43;
$foundation-light:  rgba($foundation,.05);

$gray1:       #4C5C7D;
$gray1-light: rgba(#4C5C7D,.06);
$gray2:       #7F8BA2;
$gray3:       #A4AEBF;
$gray4:       #BFC5D0;
$gray5:       #E1E5EA;
$gray6:       #FAFAFB;

$gray:          $gray1;
$gray-dark:     $gray2;
$gray-light:    $gray3;
$gray-lighter:  $gray4;
$gray-lightest: $gray5;

$alert1:                #FAFAFB;
$alert1-color:          $navy;
$alert1-hover:          #EBEBEF;
$alert2:                $blue-light;
$alert2-color:          $blue-dark;
$alert2-hover:          #D1ECFF;
$alert3:                $green-light;
$alert3-color:          $green-dark;
$alert3-hover:          #DAF6ED;
$alert4:                $orange-light;
$alert4-color:          $orange-dark;
$alert4-hover:          #FBE8D0;

// Reassign color vars to semantic color scheme
$brand-primary:             $blue;
$brand-success:             $green;
$brand-info:                $yellow;
$brand-warning:             $orange;
$brand-danger:              $red;
$brand-inverse:             $gray-dark;

$body-bg:       $white-near;
$body-color:    $navy;
$inverse-bg:    $foundation;
$inverse-color: $white;


// Links
$link-color:            $brand-primary;
$link-decoration:       none;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: underline;
$link-transition:       all .15s ease-in-out;
$link-transition-color: color .15s ease-in-out;
$link-transition-slow:  all .3s ease-in-out;
$link-transition-fast:  all .05s ease-in-out;
$link-transition-opacity:  opacity .3s ease-in-out;

// Fonts
$font-family-sans-serif: 'Karla', "Segoe UI", "Helvetica Neue", Arial, sans-serif;
$font-family-sans-title: 'Rubik', "Segoe UI", "Helvetica Neue", Arial, sans-serif;
$font-family-sans-ui:    'Roboto Web', 'Roboto', "Helvetica Neue", Arial, sans-serif;
// $font-family-serif:      'Rubik', Georgia, "Times New Roman", Times, serif;
$font-family-monospace:  'IBM Plex Mono',Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:       $font-family-sans-serif;

$font-weight-normal:  400;
$font-weight-light:   300;
$font-weight-base:    $font-weight-normal;
$font-weight-med:     500;
$font-weight-bold:    700;

$font-size-base: 1rem;         // 18px
$font-size-xl:   1.333333rem;  // 24px
$font-size-lg2:  1.222222rem;  // 22px
$font-size-lg:   1.111111rem;  // 20px
$font-size-sm:   .8888888rem;  // 16px
$font-size-xs:   .7222222rem;  // 13px
$font-size-xxs:  .5555555rem;  // 10px
$font-size-xxxs: .5rem;        // 9px

$font-size-ui-fine:.66666rem;  // 12px

$font-size-h1-md:2.444444rem;  // 44px
$font-size-h1:   3.111111rem;  // 56px
$font-size-h2:   1.777777rem;  // 32px
$font-size-h3:   1.444444rem;  // 26px
$font-size-h4:   1rem;         // 18px
$font-size-h5:   .7777777rem;  // 14px
$font-size-h6:   1.222222rem;  // 22px

$font-size-h1-sm:1.555555rem;  // 28px
$font-size-h2-sm:1.444444rem;  // 26px
$font-size-h3-sm:1.222222rem;  // 22px
$font-size-h4-sm:1rem;         // 18px
$font-size-h5-sm:.7777777rem;  // 14px
$font-size-h6-sm:1.222222rem;  // 22px

$headings-font-family:   $font-family-sans-title;
$headings-font-weight:   $font-weight-base;
$headings-line-height:   1.3;
$headings-color:         inherit;

$border-radius:          6px;
$border-radius-lg:       $border-radius;
$border-radius-sm:       2px;
$border-radius-extra:    24px;
$border-radius-cta:      16px;
$border-width:           1px;
$border-color:           $gray5;
$border-standard:        $border-width solid $gray5;
$border-standard-dark:   $border-width solid $gray4;


// Forms
$input-padding-x:                $grid-gutter-width*.75;
$input-padding-y:                $grid-gutter-width*.6;
$input-line-height:              1.25;
$input-height:                   $grid-gutter-width*2.5;
$input-bg:                       $white-dark;
$input-bg-alt:                   $gray5;
$input-color:                    $body-color;
$input-border-color:             $gray4;
$input-btn-border-width:         $border-width; // For form controls and buttons
$input-box-shadow:               none;
$input-border-radius:            $border-radius;
$input-border-radius-lg:         $border-radius-lg;
$input-border-radius-sm:         $border-radius-sm;
$input-bg-focus:                 $white;
$input-border-focus:             $blue;
$input-box-shadow-focus:         $input-box-shadow;
$input-color-focus:              $input-color;
$input-color-placeholder:        $gray3;
$input-font-weight:              $font-weight-base;
$input-font-size:                $font-size-sm;
$input-font-family:              $font-family-base;

// Buttons
$btn-padding-x:                  $grid-gutter-width*1.25;
$btn-padding-x-thin:             $grid-gutter-height;
$btn-padding-y:                  $grid-gutter-width*.6;
$btn-padding-y-fluid:            $grid-gutter-width*.5;
$btn-line-height:                1.5;
$btn-min-width:                  150px;
$btn-min-height:                 $input-height;
$btn-font-weight:                $font-weight-bold;
$btn-font-size:                  $font-size-sm;
$btn-font-family:                $font-family-base;
/*
$btn-box-shadow:                 0px 4px 35px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.15);
$btn-focus-box-shadow:           0px 4px 35px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.3);
$btn-active-box-shadow:          0px 4px 35px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.5);
*/
$btn-box-shadow:                 none;
$btn-focus-box-shadow:           none;
$btn-active-box-shadow:          none;

$btn-default-color:              $white;
$btn-default-bg:                 $purple;
$btn-default-border:             $purple;
$btn-default-hover:              $purple-light;
$btn-default-color-hover:        $purple;

$btn-stroke-color:               $purple-dark;
$btn-stroke-bg:                  transparent;
$btn-stroke-border:              $purple;
$btn-stroke-hover:               $purple;
$btn-stroke-color-hover:         $white;

$btn-stroke:                     $purple-dark;

$btn-default-dark-color:         $white;
$btn-default-dark-bg:            $purple-dark;
$btn-default-dark-border:        $purple-dark;
$btn-default-dark-hover:         $purple;
$btn-default-dark-color-hover:   $white;

$btn-primary-color:              $white;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             $brand-primary;
$btn-primary-hover:              $blue-dark;

$btn-secondary-color:            $white;
$btn-secondary-bg:               $navy;
$btn-secondary-border:           $navy;
$btn-secondary-hover:            $navy-light;

$btn-blue-light-color:           $blue-dark;
$btn-blue-light-bg:              $blue-light;
$btn-blue-light-border:          $blue-light;
$btn-blue-light-hover:           $blue;

// Dropdowns
$dropdown-min-width:             385px;
$dropdown-width:                 $dropdown-min-width;
$dropdown-width-wide:            $dropdown-min-width*1.7;
$dropdown-padding-y:             $grid-gutter-width*2;
$dropdown-padding-x:             $grid-gutter-width*2;
$dropdown-margin-top:            0;
$dropdown-bg:                    $inverse-bg;
$dropdown-border-color:          transparent;
$dropdown-border-width:          0;
$dropdown-divider-bg:            rgba($white,.1);
$dropdown-box-shadow:            0px 4px 8px rgba(0, 0, 0, 0.1);
$dropdown-link-color:            $white;
$dropdown-link-hover-color:      $blue;
$dropdown-link-hover-bg:         transparent;
$dropdown-link-active-color:     $gray5;
$dropdown-link-active-bg:        transparent;
$dropdown-item-padding-x:        0;
$dropdown-item-padding-y:        7px;
$dropdown-header-color:          $gray-lighter;

// Modals
$modal-inner-padding:         $grid-gutter-width*1.5;

$modal-dialog-margin:         10px;
$modal-dialog-sm-up-margin-y: 30px;

$modal-content-bg:               $inverse-bg;
$modal-content-color:            $inverse-color;
$modal-content-border-color:     transparent;
$modal-content-border-width:     $border-width;
$modal-content-xs-box-shadow:    none;
$modal-content-sm-up-box-shadow: none;
$modal-backdrop-bg:           transparent;
$modal-backdrop-opacity:      .5;
$modal-header-border-color:   rgba($white,.1);
$modal-footer-border-color:   $modal-header-border-color;
$modal-header-border-width:   $modal-content-border-width;
$modal-footer-border-width:   $modal-header-border-width;
$modal-header-padding:        15px;
$modal-lg:                    800px;
$modal-md:                    500px;
$modal-sm:                    300px;

$modal-transition:            transform .3s ease-out;


// Cards
/*
$card-spacer-x:            1.25rem;
$card-spacer-y:            .75rem;
*/
$card-border-width:        0;
$card-border-radius:       0;
$card-border-color:        $border-color;
$card-border-radius-inner: 0;
$card-bg:                  transparent;
$card-cap-bg:              $card-bg;

$card-link-hover-color:    $white;

$card-img-overlay-padding: 1.25rem;

$card-deck-margin:          ($grid-gutter-width-base / 2);

// Grid settings - details
$main-sm-columns:         12;
$main-md-columns:         10;
$main-md-offset:          1;
$main-lg-columns:         7;
$main-lg-offset:          0;
$main-xl-columns:         6;
$main-xl-offset:          1;
$sidebar-sm-columns:      9;
$sidebar-sm-offset:       0;
$sidebar-md-columns:      7;
$sidebar-md-offset:       1;
$sidebar-lg-columns:      5;
$sidebar-lg-offset:       0;
$sidebar-xl-columns:      4;
$sidebar-xl-offset:       0;

$main-full-md-columns:    12;
$main-full-md-offset:     0;
$main-full-lg-columns:    12;
$main-full-lg-offset:     0;
$main-full-xl-columns:    12;
$main-full-xl-offset:     0;

$main-simple-md-columns:        10;
$main-simple-md-offset:         1;
$main-simple-lg-columns:        8;
$main-simple-lg-offset:         2;

$main-plans-sm-columns:        10;
$main-plans-sm-offset:         1;
$main-plans-md-columns:        10;
$main-plans-md-offset:         1;
$main-plans-lg-columns:        8;
$main-plans-lg-offset:         2;
$main-plans-xl-columns:        8;
$main-plans-xl-offset:         2;

$sidebar-plans-sm-columns:     10;
$sidebar-plans-sm-offset:      1;
$sidebar-plans-md-columns:     6;
$sidebar-plans-md-offset:      3;
$sidebar-plans-lg-columns:     5;
$sidebar-plans-lg-offset:      0;
$sidebar-plans-xl-columns:     4;
$sidebar-plans-xl-offset:      0;

$main-feature-sm-columns:       10;
$main-feature-sm-offset:        0;
$main-feature-md-columns:       10;
$main-feature-md-offset:        0;
$main-feature-lg-columns:       6;
$main-feature-lg-offset:        0;
$sidebar-feature-sm-columns:    10;
$sidebar-feature-sm-offset:     2;
$sidebar-feature-md-columns:    10;
$sidebar-feature-md-offset:     2;
$sidebar-feature-lg-columns:    5;
$sidebar-feature-lg-offset:     1;

$main-single-sm-columns:       12;
$main-single-sm-offset:        0;
$main-single-md-columns:       10;
$main-single-md-offset:        1;
$main-single-lg-columns:       10;
$main-single-lg-offset:        1;
$main-single-xl-columns:       8;
$main-single-xl-offset:        2;


// Code

$code-font-size:              75%;
$code-padding-x:              .4em;
$code-padding-y:              .1em;
$code-color:                  $purple;
$code-bg:                     $white-dark;
$code-border:                 1px solid $gray4;
$code-radius:                 3px;
