blockquote {
  border-left: 3px solid $purple;
  margin: 1.75rem 0;
  @include media-breakpoint-up(sm) {
    margin-left: -($grid-gutter-width)-3;
  }
  padding: 0;
  padding-left: $grid-gutter-width;
  color: $gray1;
  font-style: italic;
}
blockquote.testimonial {
  footer {
    display: flex;
    align-items: center;
    img {
      width: $grid-gutter-width*2;
      height: $grid-gutter-width*2;
      margin-right: $grid-gutter-width*.5;
      border-radius: $grid-gutter-width;
    }
    cite {
      font-style: normal;
      font-size: $font-size-xs;
      line-height: 1.3;
      span {
        display: block;
        &.author {
          font-size: $font-size-sm;
          font-weight: $font-weight-bold;
          color: $body-color;
        }
      }
    }
  }
}
.sidebar,.features-cta,.footer-testimonials {
  blockquote.testimonial {
    border-left: none;
    margin: 0 0 $grid-gutter-width*2;
    padding-left: 0;
    font-style: normal;
    p {
      margin-bottom: $grid-gutter-width*.75;
    }
    footer {
      img {
        width: $grid-gutter-width*3;
        height: $grid-gutter-width*3;
        margin-right: $grid-gutter-width*.75;
        border-radius: $grid-gutter-width*1.5;
      }
    }
  }
}
.footer-testimonials {
  background-color: $purple-light;
  padding: $grid-gutter-height*1.5 0 0;
  margin-top: $grid-gutter-width*5;
  @include media-breakpoint-up(lg) {
    margin-top: $grid-gutter-width*7.5;
  }
  @include media-breakpoint-up(xl) {
    margin-top: $grid-gutter-width*9;
  }
  .col-12 {
    margin-bottom: $grid-gutter-height*1.5;
  }
  blockquote.testimonial {
    @extend .shadow-box-low;
    margin-bottom: 0;
    padding: $grid-gutter-width*2 $grid-gutter-width*2.25;
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width*2 $grid-gutter-width*3;
    }
    @include media-breakpoint-up(xl) {
      padding: $grid-gutter-width*3 $grid-gutter-width*4;
    }
  }
}
