.home {
  .wrap {
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center top;
    .slick-initialized {
      min-width: 100%;
      width: 0;
    }
/*
    background: url(../images/bg/chat-illustration.png) no-repeat left bottom;
    background-size: 430px auto;
*/
//     padding-bottom: $grid-gutter-width*5;
    @include media-breakpoint-down(sm) {
//       background-position: -40px 102%;
    }
    @include media-breakpoint-up(md) {
//       padding-bottom: $grid-gutter-width*3.5;
//       background-position: -5px 100%;
    }
  }
  .hero {
    padding-top: $grid-gutter-height*2;
    padding-bottom: $grid-gutter-width*1;
    @include media-breakpoint-up(sm) {
//       padding-top: $grid-gutter-height*1.25;
    }
    @include media-breakpoint-up(md) {
//       padding-top: $grid-gutter-height*6;
    }
    @include media-breakpoint-up(lg) {
      padding-top: $grid-gutter-height*1.5;
      padding-bottom: $grid-gutter-height*1.5;
/*
      padding-top: $grid-gutter-height*9;
      padding-bottom: $grid-gutter-width*2;
*/
    }
    &>.row {
      align-items: center;
    }
    .headline {
      p.h4 {
        @extend .hidden-sm-down;
      }
      h1 {
        margin-bottom: 1em;
//         margin-bottom: $grid-gutter-height*1.5;
        @include media-breakpoint-up(md) {
//           margin-bottom: 1em;
        }
        @include media-breakpoint-up(xl) {
//           margin-bottom: 1.4em;
        }
      }
    }
    .signup {
      form {
//         margin-bottom: $grid-gutter-height*2;
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
        @include media-breakpoint-up(xl) {
//           margin-bottom: $grid-gutter-width*3;
        }
        input {
          margin-right: $grid-gutter-width/2;
          @include media-breakpoint-up(xl) {
//             min-width: 250px;
          }
        }
        button {
          @include media-breakpoint-up(xl) {
            width: 140px;
          }
        }
        input,button,.form-group {
          width: 100%;
          @include media-breakpoint-up(sm) {
            width: auto;
          }
        }
        .form-group {
          @include media-breakpoint-down(xs) {
            margin-bottom: $grid-gutter-height;
          }
        }
      }
    }
    .illustration {
//       margin-bottom: $grid-gutter-width*2;
      @include media-breakpoint-up(md) {
//         margin-bottom: $grid-gutter-width;
      }
      @include media-breakpoint-up(lg) {
//         margin-bottom: 0;
      }
      img {
        width: 100%;
        height: auto;
        @include media-breakpoint-up(sm) {
//           margin-top: -$grid-gutter-width*1.25;
        }
        @include media-breakpoint-up(md) {
//           width: 104%;
        }
        @include media-breakpoint-up(lg) {
//           width: 130%;
//           margin-top: -$grid-gutter-width*2;
//           margin-left: -$grid-gutter-width*3;
        }
        @include media-breakpoint-up(xl) {
//           width: 120%;
//           margin-top: -$grid-gutter-width*5;
//           margin-left: 0;
        }
      }
    }
    .details {
      font-size: $font-size-lg;
      line-height: 1.6;
      h3 {
        margin-bottom: .6em;
      }
      p {
        margin-bottom: 1.5rem;
      }
      @include media-breakpoint-up(xl) {
        margin-top: -$grid-gutter-width*2;
      }
    }
    &.hero-merge {
      padding-top: $grid-gutter-height*3;
      padding-bottom: $grid-gutter-width*3;
      @include media-breakpoint-up(sm) {
        padding-top: $grid-gutter-height*3.5;
        padding-bottom: $grid-gutter-width*3.5;
      }
      @include media-breakpoint-up(md) {
        padding-top: $grid-gutter-height*4;
        padding-bottom: $grid-gutter-width*4;
      }
      @include media-breakpoint-up(lg) {
        padding-top: $grid-gutter-height*5;
        padding-bottom: $grid-gutter-height*5;
      }
      .hero-title {
        @include media-breakpoint-up(md) {
          text-align: center;
        }
        h1 {
          margin-bottom: .5em;
          @include media-breakpoint-only(md) {
            font-size: $font-size-h1-md;
          }
        }
      }
      .hero-subtitle {
        color: $gray1;
        @include media-breakpoint-up(md) {
          font-size: $font-size-lg;
          text-align: center;
        }
      }
      .hero-signup {
        .gform_wrapper {
          .gform_validation_errors {
            padding: 0;
            background-color: transparent;
            border: none;
            box-shadow: none;
          }
          form {
            background-color: rgba($white,.4);
            padding: 8px;
            border: 1px solid #e3e3e3;
            border-radius: 16px;
            .gform_body {
              .validation_message {
                padding: 0;
                background-color: transparent;
                border: none;
                line-height: 1;
              }
            }
            .gform_footer {
              input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]) {
                min-height: 0 !important;
                min-width: 0 !important;
                padding: 8px 16px !important;
                white-space: nowrap;
              }
            }
          }
        }
      }
      .hero-images {
        padding-top: $grid-gutter-height*3;
        @include media-breakpoint-up(sm) {
          padding-top: $grid-gutter-height*3.5;
        }
        @include media-breakpoint-up(md) {
          padding-top: $grid-gutter-height*4;
        }
        @include media-breakpoint-up(lg) {
          padding-top: $grid-gutter-height*5;
        }
        &>div {
          position: relative;
          @include media-breakpoint-down(md) {
            height: 700px;
          }
        }
        img {
          &.hero-img-mobile {
            position: absolute;
            max-width: 756px;
            height: auto;
          }
          &.hero-img-tablet {
            position: absolute;
            max-width: 889px;
            height: auto;
          }
        }
      }
      .vidlink {
        margin-top: $grid-gutter-height*1.5;
        @include media-breakpoint-up(md) {
          margin-top: $grid-gutter-height*2;
        }
        text-align: center;
        font-size: $font-size-sm;
        font-weight: $font-weight-med;
        justify-content: center;
        align-content: center;
        a {
          color: $navy;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          i {
            font-size: $font-size-xl;
            margin-right: 10px;
          }
          @include hover-focus {
            color: $blue;
            text-decoration: none;
          }
        }
      }
    }
  }
}

