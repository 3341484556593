html {
  font-size: 18px;
  scroll-behavior: smooth;
}
body {
  line-height: 1.6;
  padding-top: 89px;
  @include media-breakpoint-up(md) {
    padding-top: 93px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 94px;
  }
  &.page-template-template-in-app {
    padding: 0 0 $grid-gutter-width*3;
  }
  &.alertbar-engaged {
    padding-top: 169px;
    @include media-breakpoint-up(md) {
      padding-top: 173px;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 174px;
    }
  }
}
:target {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}
.mainwrap {
  display: flex;
  flex-wrap: wrap;
  flex: 100%;
}
.main {
  ul:not(.gform_fields) {
    @extend .bullet-check;
    margin-left: 1rem;
    li {
      background-position-y: 3px;
    }
  }
}
.row.vertical-center {
  align-items: center;
}

/***** TYPE *****/
h1,.h1 {
  font-size: $font-size-h1;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-h1-sm;
  }
}
h2,.h2 {
  font-size: $font-size-h2;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-h2-sm;
  }
}
h3,.h3 {
  font-size: $font-size-h3;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-h3-sm;
  }
}
h4,.h4 {
  font-size: $font-size-h4;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-h4-sm;
  }
}
h5,.h5 {
  font-size: $font-size-h5;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-h5-sm;
  }
}
h6,.h6 {
  font-size: $font-size-h6;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-h6-sm;
  }
}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  margin-bottom: 1em;
  &.gradient {
    background: linear-gradient(270deg, #2222C4 6.23%, #0094FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}
h1,.h1,h2,.h2 {
  letter-spacing: .01em;
}
h4,.h4,h5,.h5 {
  letter-spacing: .06em;
  text-transform: uppercase;
  font-weight: $font-weight-med;
}
.emphasize {
  color: $body-color;
}
.intro-text {
  font-size: $font-size-lg;
}
mark {
  background-color: #FAF0D1;
  padding: .05em;
}
p,ul,ol,blockquote,.wp-block-image,.alertbox {
  margin-bottom: 1.75rem;
}
code {
  border: $code-border;
  border-radius: $code-radius;
  font-weight: $font-weight-med;
  margin: 0 1px;
}
.alertbox {
  background: $white-dark url(../images/alertbox.svg) no-repeat $grid-gutter-width*1.25 $grid-gutter-width*1.75;
  border: $border-color solid $border-width;
  border-radius: $border-radius;
  padding: $grid-gutter-width*1.5;
  padding-left: $grid-gutter-width*4;
  p:last-child {
    margin-bottom: 0;
  }
}
/***** LINKS *****/
a {
  @include transition($link-transition);
}
.feature-brick {
  background-color: $white-near;
  display: block;
  margin-bottom: $grid-gutter-width*.75;
  border: 1px solid $gray5;
  border-radius: $border-radius;
  position: relative;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
  color: $body-color;
  text-decoration: none;
  padding: $grid-gutter-width*1.25 $grid-gutter-width*.75;
  padding-left: $grid-gutter-width*3.5;
  line-height: 1.3;
  img {
    width: $grid-gutter-width*2;
    position: absolute;
    left: $grid-gutter-width*.75;
    top: 50%;
    transform: translateY(-50%);
  }
  @include hover-focus {
    text-decoration: none;
    color: $body-color;
  }
}

.wp-block-image figcaption {
  font-size: $font-size-xs;
  color: $gray1;
  margin: $grid-gutter-width*.75 0 $grid-gutter-width*1.5;
}
.wp-block-button {
  text-align: center;
  padding-top: $grid-gutter-width;
  a {
    @extend .btn;
    @extend .btn-thin;
    color: $navy !important;
  }
}
