header.banner {
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: $body-bg;
  background-color: rgba($body-bg,.98);
  padding: $grid-gutter-width*1.2 0;
  .logged-in.admin-bar & {
    top: 46px;
    @media screen and (min-width: 783px) {
      top: 32px;
    }
  }
  .brand {
    display: block;
    img {
      height: $grid-gutter-width*2.25;
      @include media-breakpoint-down(sm) {
        height: $grid-gutter-width*2;
      }
    }
  }
  .nav-toggler {
    border: none;
    background-color: transparent;
    font-size: $font-size-h3;
    float: right;
    padding: 0 $grid-gutter-width*.5;
    color: $gray1;
    &:active {
      outline:none;
    }
    @include hover-focus {
      outline:none;
    }
    img {
      width: 20px;
      height: auto;
      margin: 0;
      padding: 0;
    }
  }
  .navbar-nav {
    flex-direction: row;
    justify-content: space-evenly;
    .nav-item {
      .nav-link {
        color: $body-color;
        @include hover-focus {
          color: $blue;
        }
        @include media-breakpoint-down(lg) {
          font-size: $font-size-sm;
        }
        &.dropdown-toggle {
          i {
            font-size: 0.75em;
            margin-left: 0.3em;
            color: rgba($body-color,.6);
          }
          &:after {
//             display: inline;
            display: none;
            font-family: 'Font Awesome 5 Pro';
            content: "\f078"; /* chevron down */
            font-size: 0.75em;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            margin-left: .9em;
            opacity: .6;

/*
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 0.3em;
            vertical-align: middle;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-left: 0.3em solid transparent;
*/
          }
        }
      }
      &.active {
        .nav-link {
          color: $blue;
          &.dropdown-toggle {
            &:after {

            }
          }
        }
      }
    }
  }

  nav.nav-ancillary {
    .navbar-nav {
      justify-content: flex-end;
      li.menu-item {
        margin-left: $grid-gutter-width*.75;
        &:first-child {
          margin-left: 0;
        }
        &.button-stroke,&.button-primary {
          &>a {
            @extend .btn;
            @extend .btn-thin;
          }
        }
        &.button-primary {
          &>a {
            @extend .btn-blue-light;
          }
        }
      }
    }
  }
  .alertbar-wrap {
    position: relative;
    display: none;
    .alertbar-engaged & {
      display: block;
    }
    a.closealert {
      cursor: pointer;
      z-index: 100;
      position: absolute;
      right: $grid-gutter-width*1;
      top: 7px;
      color: $alert1-color;
      padding: 10px;
    }
    .alertbar {
      display: flex;
      position: relative;
      z-index: 20;
      width: 100%;
      min-height: $grid-gutter-width*3;
      line-height: 1.2;
      padding: $grid-gutter-width*.75 $grid-gutter-width*2.25 $grid-gutter-width*.75 $grid-gutter-width;
      margin: 0 0 $grid-gutter-width;
      color: $alert1-color;
      background-color: $alert1;
      text-align: center;
      border-radius: $border-radius-lg;
      span.detail {
        margin: auto;
      }
      &+a.closealert {
        @include hover-focus {
          opacity: .7;
        }
      }
      &.level2 {
        color: $alert2-color;
        background-color: $alert2;
      }
      &.level3 {
        color: $alert3-color;
        background-color: $alert3;
        &+a.closealert {
          color: $alert3-color;
          @include hover-focus {
            opacity: .7;
          }
        }
      }
      &.level4 {
        color: $alert4-color;
        background-color: $alert4;
        &+a.closealert {
          color: $alert4-color;
          @include hover-focus {
            opacity: .7;
          }
        }
      }
    }
    a.alertbar {
      text-decoration: none;
      @include hover-focus {
        background-color: $alert1-hover;
        &.level2 {
          background-color: $alert2-hover;
        }
        &.level3 {
          background-color: $alert3-hover;
        }
        &.level4 {
          background-color: $alert4-hover;
        }
      }
    }
  }
  .btn {
    white-space: nowrap;
  }
}
.nav-mobile-primary {
  .nav-item {
    a {
      font-size: $font-size-lg;
      padding: $dropdown-item-padding-y 0;
      line-height: 1.2;
      color: $white;
      @include hover-focus {
        @extend .nav-dark-hover;
      }
    }
    &.dropdown-header {
      padding: $grid-gutter-width*1 0 0;
      a {
        @extend .nav-link-header;
        font-size: $font-size-sm;
        font-weight: $font-weight-base;
      }
    }
    &.dropdown-border {
      @extend .nav-divider-border;
      padding-bottom: 0;
//       padding-top: $grid-gutter-width;
      &.dropdown-header {
//         padding-top: 0;
      }
    }
    &.button-white {
      a {
        @extend .btn;
        @extend .btn-thin;
        color: $white;
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
        @include hover-focus {
          border-color: $white-near;
          color: $white;
        }
      }
      &.link-center {
        text-align: center;
      }
    }
    .nav-link {
      b {
        @extend .nav-tag-highlight;
        font-size: $font-size-xs;
      }
      small {
        @extend .nav-small-info;
      }
    }
  }
  .btn {
    white-space: nowrap;
  }
}


// The dropdown menu
.dropdown {
  .dropdown-menu {
    padding: $dropdown-padding-y $dropdown-padding-x;
    width: $dropdown-width;
    left:50%;
    margin-top: $grid-gutter-width/2;
    margin-left:-$dropdown-width/2;
    .dropdown-mark {
      width: $grid-gutter-width*1.5;
      height: $grid-gutter-width;
      background: url(../images/dropdown-mark.png) no-repeat center top;
      background-size: auto 100%;
      position: absolute;
      top: -$grid-gutter-width*.55;
      left: 50%;
      margin-left: -$grid-gutter-width*.75;
    }
    .dropdown-item {
      padding: $dropdown-item-padding-y $dropdown-item-padding-x;
      line-height: 1.2;
      white-space: normal;
      b {
        @extend .nav-tag-highlight;
      }
      small {
        @extend .nav-small-info;
      }
      @include hover-focus {
        @extend .nav-dark-hover;
      }
      &.dropdown-header {
        @extend .nav-link-header;
      }
      &.dropdown-border {
        @extend .nav-divider-border;
      }

    }
    &.fade {
      @include transition($link-transition-opacity);
    }
  }
  &.show {
    &>.dropdown-menu.fade {
      @include transition($link-transition-opacity);
      pointer-events: auto;
      opacity: 1;
    }
  }
  &:not(.dropdown-2col) {
    .dropdown-item {
      &:first-child {
        padding-top: 0;
      }
      &:nth-last-child(2) {
        padding-bottom: 0;
        small {
          margin-bottom: 0;
        }
      }
    }
  }
}
.dropdown-2col {
  .dropdown-menu {
    width: $dropdown-width-wide;
    margin-left:-$dropdown-width-wide/2;
    .col-6 {
      .dropdown-item {
        &:last-child() {
          padding-bottom: 0;
        }

      }
    }
  }
}

