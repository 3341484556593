.entry-meta {
  display: flex;
  align-items: center;
  margin-bottom: $grid-gutter-width*1.5;
  img {
    width: $grid-gutter-width*2;
    height: $grid-gutter-width*2;
    margin-right: $grid-gutter-width*.75;
    border-radius: $grid-gutter-width;
  }
  .info {
    font-size: $font-size-xs;
    line-height: 1.4;
    color: $gray1;
    time {
      display: block;
    }
    p.author {
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      color: $body-color;
      margin: 0;
    }
  }
}
h5 {
  &.cat_361 {
    color: $blue;
  }
  &.cat_457 {
    color: $orange;
  }
  &.cat_512 {
    color: $purple;
  }
}

.single-post {
  .page-header {
    margin-bottom: $grid-gutter-width; // in addition to H1's margin
    .entry-meta {
      img {
        width: $grid-gutter-width*3;
        height: $grid-gutter-width*3;
        border-radius: $grid-gutter-width*1.5;
      }
    }
    .return-to-list {
      @include transition($link-transition-color);
      display: block;
      margin-bottom: $grid-gutter-height*3.5;
      color: $gray2;
      text-decoration: none;
      svg,img {
        @include transition($link-transition-color);
        width: 15px;
        margin-right: $grid-gutter-height*.5;
        path {
          stroke: $gray2;
        }
      }
      @include hover-focus {
        color: $blue;
        svg path {
          stroke: $blue;
        }
      }
    }
    h5.news-cat {
      margin-bottom: $grid-gutter-height*.25;
    }
    time.news-date {
      display: block;
      color: $gray1;
      font-size: $font-size-xs;
      margin-bottom: $grid-gutter-height*2;
    }

  }
  .featured-banner {
    margin-bottom: $grid-gutter-width*3;
    img {
      width: 100%;
      height: auto;
    }
  }
  .entry-content {
    font-size: $font-size-lg;
    h1,h2,h3 {
      color: $gray1;
      margin-top: $grid-gutter-width*2;
    }
    h4 {
      margin-top: $grid-gutter-width*2;
      color: $purple;
    }
    a {
      overflow-wrap: anywhere;
    }
    .addtoany_content {
      text-align: right;
      margin: $grid-gutter-width*2.5 0 0;
      padding: 0 0 $grid-gutter-width;
      border-bottom: $border-standard-dark;
      .addtoany_list {
        a {
          padding: 0 $grid-gutter-width*.5;
          img {
            width: $grid-gutter-width*1.2;
          }
        }
      }
    }
  }
}

body.blog,body.archive {
  .main {
    margin-top: $grid-gutter-height*4;

    .featured-entry {
      @extend .tile-box;
      position: relative;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: $grid-gutter-height*6;
      color: $body-color;
      text-decoration: none;
      h5.strapline {
        color: $purple;
        text-align: right;
        width: 100%;
        padding: $grid-gutter-height*1.5 $grid-gutter-height*1.5 0;
        margin-bottom: 0;
        @include media-breakpoint-up(lg) {
          position: absolute;
          top: 0;
          right: 0;
          text-align: left;
          width: auto;
        }
      }
      h1 {
        margin-bottom: .6em;
        @include media-breakpoint-down(lg) {
          font-size: $font-size-h2;
        }
      }
      .entry-meta {
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
          img {
            width: $grid-gutter-width*3;
            height: $grid-gutter-width*3;
            border-radius: $grid-gutter-width*1.5;
          }
        }
      }
      figure {
        margin: 0;
        padding: 0;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        background-color: $blue-light;
        img {
          @extend .img-fluid;
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;
        }
        @include media-breakpoint-up(lg) {
          border-bottom-left-radius: $border-radius;
          border-top-right-radius: 0;
          img {
            border-bottom-left-radius: $border-radius;
            border-top-right-radius: 0;
          }
        }
      }
      div.col-12 {
        padding: $grid-gutter-height*2 $grid-gutter-height*1.5;
        @include media-breakpoint-up(md) {
          padding: $grid-gutter-height*3;
          padding-top: $grid-gutter-height*2;
        }
        @include media-breakpoint-up(lg) {
          align-self: center;
          padding: $grid-gutter-height*3;
        }
      }
    }

    .most-recent-entries {
      margin-bottom: $grid-gutter-height*5;
      h5 {
        color: $gray2;
        margin: 0 0 $grid-gutter-height*1.5 $grid-gutter-width*1.5;;
      }
      &>.row {
        &>div {
          margin-bottom: $grid-gutter-width;
        }
      }
      .recent-entry {
        @extend .tile-box-shadowless;
        height: 100%;
        display: block;
        padding: $grid-gutter-height*3 $grid-gutter-width*1.5;
        color: $gray2;
        text-decoration: none;
        h2 {
          color: $body-color;
        }
        .excerpt {
          font-size: $font-size-sm;
          display: block;
          margin-bottom: $grid-gutter-width*1.5;
        }
      }
    }

    .alm-btn-wrap {
      margin-top: $grid-gutter-height*4;
      text-align: center;
    }
    .alm-load-more-btn {
      @extend .btn;
      @extend .btn-thin;
      &.done {
        display: none;
      }
    }
    .entry-excerpt-ajax {
      margin-bottom: $grid-gutter-height*3;
      .credits {
        font-size: $font-size-sm;
        margin: 0 0 .25em;
        color: $gray2;
      }
      h3 {
        margin-bottom: 0;
        a {
          color: $body-color;
          text-decoration: none;
          @include hover-focus {
            color: $link-color;
          }
        }
      }
    }
  }
}


.related-bricks {
  margin-top: $grid-gutter-width*3.5;
  h5 {
    color: $gray2;
    margin-bottom: $grid-gutter-width*1.25;
  }
}
.related-tiles {
  margin-top: $grid-gutter-width*5;
  .related-tile {
    margin-bottom: $grid-gutter-width;
    a {
      display: block;
      height: 100%;
      background-color: $white;
      border: $border-standard;
      border-radius: $border-radius;
      color: $body-color;
      text-decoration: none;
      figure {
        margin: 0;
        padding: 0;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        background-color: $blue-light;
        img {
          @extend .img-fluid;
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;
        }
      }
      h6 {
        padding: $grid-gutter-width*1.5 $grid-gutter-width*1.2;
        margin-bottom: 0;
      }
      @include hover-focus {
        @extend .shadow-box-low;
      }
    }
    &:nth-child(3) {
      @include media-breakpoint-between(sm,md) {
        display: none;
      }
    }
  }
  &.most-read {
    margin-top: 0;
    margin-bottom: $grid-gutter-height*6;
    h5 {
      color: $gray2;
      margin: 0 0 $grid-gutter-height*1.5 $grid-gutter-width*1.5;;
    }
  }
  &.from-blog {
    padding: 0;
    margin-top: $grid-gutter-height*7;
    margin-bottom: 0;
    &>.row {
      margin-left: 0;
      margin-right: 0;
    }
    h5 {
      color: $gray2;
      margin: 0 0 $grid-gutter-height*1.5 $grid-gutter-width*1.5;;
    }
  }
}

.page-template-template-news .featured-entry,.news-article-tiles article a {
  border: $border-standard;
  border-radius: $border-radius;
  text-decoration: none;
  color: $body-color;
  .entry-title {
    @include transition($link-transition-color);
    color: $body-color;
  }
  h5 {
    margin-bottom: $grid-gutter-height*.25;
  }
  time {
    display: block;
    color: $gray1;
    font-size: $font-size-sm;
    margin-bottom: $grid-gutter-height;
  }
  @include hover-focus {
    @extend .shadow-box-low;
    background-color: transparent;
    .entry-title {
      color: $link-color;
    }
  }
}
.page-template-template-news .featured-entry {
  margin: 0 0 $grid-gutter-width;
  figure {
    margin: 0;
    padding: 0;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
//       background-color: $blue-light;
    img {
      @extend .img-fluid;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
    @include media-breakpoint-up(md) {
      align-self: center;
      border-bottom-left-radius: $border-radius;
      border-top-right-radius: 0;
      img {
        border-bottom-left-radius: $border-radius;
        border-top-right-radius: 0;
      }
    }
  }
  &>div {
    padding: $grid-gutter-height*3 $grid-gutter-height*2;
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-height*2;
      align-self: center;
    }
    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-height*3 $grid-gutter-width*3;
    }
    @include media-breakpoint-up(xl) {
      padding: $grid-gutter-height*4 $grid-gutter-width*5;
    }
    .entry-title {
      @extend .h3;
    }
    h5 {
      color: $green;
    }
  }
}
.news-article-tiles {
  &.row {
    margin: 0;
  }
  article {
    margin-bottom: $grid-gutter-width;
    a {
      display: block;
      height: 100%;
      padding: $grid-gutter-height*3 $grid-gutter-height*2;
      h5 {
        color: $gray4;
        &.cat_361 {
          color: $blue;
        }
        &.cat_457 {
          color: $orange;
        }
        &.cat_512 {
          color: $purple;
        }
      }
      .entry-title {
        @extend .h6;
        margin-bottom: 0;
      }
    }
  }
  &.article-footer {
    margin-top: $grid-gutter-height*6;
    &>.row {
      margin: 0;
    }
  }
}
.ip-block-tiles {
  margin-top: 0;
  margin-bottom: $grid-gutter-height*2;
  @include media-breakpoint-up(md) {
    margin-bottom: $grid-gutter-height*3;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: $grid-gutter-height*4;
  }
  .ip-tile {
    @include media-breakpoint-up(md) {
      @include make-col(4);
    }
    margin-bottom: $grid-gutter-width;
    .ip-tile-link {
      display: block;
      height: 100%;
      color: $gray1;
      text-decoration: none;
      background: $white-near;
      border: 1px solid $gray5;
      border-radius: $border-radius*2;
      padding: $grid-gutter-width;
      .media {
        figure {
          width: 200px;
          max-width: 100%;
          img {
            @include img-fluid;
            max-height: 100%;
            position: relative;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      .details {
        line-height: 1.5;
        h4 {
          text-transform: inherit;
          color: $body-color !important;
          letter-spacing: inherit;
          margin-bottom: $grid-gutter-height/2 !important;
        }
      }
      @include hover-focus {
        border-color: $gray4;
      }
    }
  }
  &.featured-integrations {
    .ip-tile {
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
      .ip-tile-link {
        &>.row {
          @include media-breakpoint-up(lg) {
            height: 100%;
            align-items: center;
          }
        }
        .media {
          figure {
            border-radius: $border-radius/2;
            aspect-ratio: 11/7;
            overflow: hidden;
            padding: $grid-gutter-height*1.5;
            text-align: center;
            height: 128px;
            @include media-breakpoint-up(lg) {
              width: 100%;
              margin-bottom: 0;
              height: 104px;
            }
            @include media-breakpoint-up(xl) {
              height: 136px;
            }
            img {
              left: auto;
            }
          }
          @include media-breakpoint-up(lg) {
            @include make-col(5);
          }
        }
        .details {
          h4 {
            @extend .h6;
          }
          @include media-breakpoint-up(lg) {
            @include make-col(7);
          }
        }
      }
    }
  }
  &.all-integrations {
    .ip-tile {
      .ip-tile-link {
        .media {
          figure {
            background-color: transparent;
            height: 44px;
            img {
              width: auto;
            }
          }
        }
      }
    }
  }
}
.ip-block {
  color: $gray1;
  margin-top: 0;
  margin-bottom: $grid-gutter-height*3;
  @include media-breakpoint-up(lg) {
    font-size: $font-size-lg;
  }
  .single-integration &,.single-partner & {
    margin-bottom: $grid-gutter-height*4;
    @include media-breakpoint-up(md) {
      margin-bottom: $grid-gutter-height*5;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: $grid-gutter-height*7;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: $grid-gutter-height*8;
    }
  }
  .ip-wrapper {
    @include last-child-zero-margin;
    padding: $grid-gutter-width*1.2;
    border: 1px solid $gray5;
    border-radius: $border-radius-cta;
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width*2.4;
      border-radius: $border-radius-cta*1.5;
    }
    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width*3 0;
      border-radius: $border-radius-cta*2;
    }
    @include media-breakpoint-up(xl) {
      padding: $grid-gutter-width*4 0;
      border-radius: $border-radius-cta*2;
    }
    .ip-content {
      @include last-child-zero-margin;
      .row.ip-row {
        @include media-breakpoint-up(lg) {
          flex-direction: row-reverse;
        }
        .single & {
          margin-bottom: $grid-gutter-height*3;
        }
        .media {
          figure {
            aspect-ratio: 1/1;
            overflow: hidden;
            border-radius: 50%;
            width: 220px;
            margin: 0 auto $grid-gutter-width*1.2;
            @include media-breakpoint-up(md) {
              width: 300px;
            }
            @include media-breakpoint-up(lg) {
              width: 100%;
              margin: 0 0 0 $grid-gutter-width;
            }
            img {
              border-radius: 0;
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
        }
        .details {
          @include last-child-zero-margin;
          figure.logo {
            margin: 0 auto $grid-gutter-height*2;
            text-align: center;
            img {
              max-width: 220px;
              max-height: 75px;
              width: auto;
              height: auto;
            }
            @include media-breakpoint-up(md) {
              img {
                max-width: 300px;
                max-height: 100px;
              }
            }
            @include media-breakpoint-up(lg) {
              margin: 0 0 $grid-gutter-height*3;
              text-align: left;
            }

          }
          h3.h2 {
            color: $body-color;
            margin-bottom: $grid-gutter-height*1.25;
          }
          p {
            margin-bottom: $grid-gutter-height;
          }
          ul,ol {
            @include last-child-zero-margin;
            padding: $grid-gutter-height;
            margin: 0 0 1em;
            background-color: $gray6;
            border-radius: $border-radius;
            list-style-type: inherit;
            li {
              background-image: none;
              padding: 0;
              margin: 0 0 .4em 1em;
            }
          }
          .btn {
            margin-top: $grid-gutter-height*1.25;
          }
        }
      }
      .ip-gallery {
        margin-bottom: $grid-gutter-width/2;
        .ip-gallery-item {
          margin-bottom: $grid-gutter-width/2;
          a {
            display: block;
            aspect-ratio: 8/5;
            overflow: hidden;
            border: 1px solid $gray4;
            border-radius: $border-radius;
            img {
              border-radius: 0;
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
            @include hover-focus {
              box-shadow: 0px 4px 8px rgba($black, 0.1);
            }
          }
        }
      }
      .ip-tags {
        margin-bottom: $grid-gutter-height*2;
        span {
          font-size: $font-size-base;
          color: $purple-dark;
          background-color: $purple-light;
          display: inline-block;
          padding: 4px 16px;
          line-height: 1;
          margin: 0 8px 0 0;
          border-radius: $border-radius-extra;
        }
      }
      .ip-notes {
        margin-top: $grid-gutter-height*3;
        padding: $grid-gutter-width*1.25;
        background-color: $purple-light;
        border-radius: $border-radius;
        color: $gray1;
        h3.h6 {
          color: $body-color;
          font-size: $font-size-base;
          font-weight: $font-weight-med;
          line-height: $grid-gutter-width*1.25;
          img,svg {
            max-height: $grid-gutter-width*1.25;
            width: auto;
            margin-right: .4em;
          }
        }
      }
    }
  }
}

