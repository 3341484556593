.container-extra {
  @include make-container();
  @include make-container-max-widths();
  @include media-breakpoint-up(lg) {
    width: 100%;
  }
  @include media-breakpoint-up(xl) {
    max-width: 1440px;
  }
  .row-extra {
    @include make-row();
    padding: 0 $grid-gutter-width;
    @include media-breakpoint-up(sm) {
      padding: 0 $grid-gutter-width/2;
    }
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
    @include media-breakpoint-up(xl) {
      padding: 0 $grid-gutter-width*1.5;
    }

    section.extra-wrap {
      width: 100%;
      border-radius: $border-radius-extra;
      @include media-breakpoint-up(lg) {
        border-radius: 0;
      }
      @include media-breakpoint-up(xl) {
        border-radius: $border-radius-extra*2;
      }
    }
  }
}
.page-template-template-reusable {
  font-size: $font-size-lg;
}

.calendly-inline-widget {
  @extend .shadow-box;
  margin-bottom: $grid-gutter-height*3;
}
.page-header {
  padding-top: $grid-gutter-height*4;
  @include media-breakpoint-up(md) {
    padding-top: $grid-gutter-height*5;
  }
  @include media-breakpoint-up(lg) {
    padding-top: $grid-gutter-height*6;
  }
  .page-template-template-simple & {
    h1 {
      @extend .h2;
      margin-bottom: $grid-gutter-width*1.75;
    }
  }
  .page-template-template-plans & {
    text-align: center;
    padding-bottom: $grid-gutter-width;
  }
  .page-template-template-news & {
    text-align: center;
    p {
      @include media-breakpoint-up(md) {
        text-align: right;
      }
      margin-bottom: $grid-gutter-height;
    }
  }

  .page-template-template-features & {
    padding-top: $grid-gutter-width*3;
    padding-bottom: $grid-gutter-width*3;
    @include make-col($main-full-md-columns);
    @include make-col-offset($main-full-md-offset);
    @include media-breakpoint-up(lg) {
      padding-top: $grid-gutter-width*4;
      padding-bottom: $grid-gutter-width*5;
    }
    @include media-breakpoint-up(xl) {
      padding-bottom: $grid-gutter-width*8;
    }
    .features-header {
      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
      }
    }
    .img {
      img {
        width: 100%;
        height: auto;
      }
    }
    .titles {
      display: flex;
      flex-direction: column-reverse;
      align-self: center;
      h1 {
        @extend .h2;
      }
    }
  }
  .page-template-template-solutions & {
    display: flex;
    flex-direction: column-reverse;
  }
  .page-id-57 &,.schedule-demo &,.page-template-template-calendly &  {
    text-align: center;
    padding-bottom: $grid-gutter-height*4;
    h1 {
      margin-bottom: $grid-gutter-height*2;
    }
    p {
      margin-bottom: 0;
      font-size: $font-size-lg;
      color: $gray1;
    }
  }
  .strapline {
    @extend h5;
    &.objective {
      color: $green;
    }
    &.organization,&.colleagues {
      color: $blue;
    }
    &.patients {
      color: $purple;
    }
    &.features {
      color: $orange;
    }
  }
}

/***** FEATURE PAGES *****/
.page-template-template-features {
  .wrap {
    padding-bottom: $grid-gutter-width*2;
    @include media-breakpoint-up(lg) {
      padding-bottom: $grid-gutter-width*4;
    }
  }
  main.main {
    font-size: $font-size-lg;
  }
  .sidebar,.features-cta {
    .testimonial {
      @extend .shadow-box-low;
      padding: $grid-gutter-width*2 $grid-gutter-width*2.25;
      @include media-breakpoint-up(md) {
        padding: $grid-gutter-width*2 $grid-gutter-width*3;
      }
      @include media-breakpoint-up(xl) {
        padding: $grid-gutter-width*3 $grid-gutter-width*4;
      }
    }
  }
  .feature-highlights {
    padding-bottom: $grid-gutter-width*2;
    @include media-breakpoint-up(lg) {
      padding-bottom: $grid-gutter-width*4;
    }
    .feature-brick {
      margin-bottom: $grid-gutter-width;
    }
  }
  .feature-highlight {
    padding-top: $grid-gutter-width*3;
    padding-bottom: $grid-gutter-width*4.5;
    border-top: $border-standard;
    .feature-details {
      .feature-icon {
        width: $grid-gutter-width*3;
        height: $grid-gutter-width*3;
        margin-bottom: $grid-gutter-width;
      }
    }
    .feature-img {
      margin-top: $grid-gutter-width;
      @include media-breakpoint-up(lg) {
        margin-top: $grid-gutter-width*4;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .features-cta {
    background-color: $blue-light;
    padding: $grid-gutter-width*1.4 0;
    .testimonial {
      margin-bottom: 0;
    }
    h3 {
      color: $blue-dark;
      margin: $grid-gutter-width*2 0 $grid-gutter-width*1.25;
    }
  }
  .features-faq {
    padding-top: $grid-gutter-width*4;
    @include media-breakpoint-up(lg) {
      padding-top: $grid-gutter-width*5;
    }
    h4 {
      color: $gray1;
      margin-bottom: $grid-gutter-width*1.5;
    }
  }
}

/***** PLANS PAGES *****/
.page-template-template-plans {
  main.main {
    font-size: $font-size-lg;
  }
  aside.sidebar {
    img {
      width: 100%;
      height: auto;
    }
  }
  @include media-breakpoint-up(lg) {
/*
    .mainwrap {
      flex-direction: row-reverse;
    }
*/
  }
  .plans-wrap {
    padding-bottom: $grid-gutter-width*3.5;
    &>.row {
      justify-content: center;
    }
    @include media-breakpoint-up(md) {
//       padding-bottom: $grid-gutter-width*5.5;
    }
    &>.row {
      &>div {
        margin-bottom: $grid-gutter-width;
      }
    }
    .plan {
      border: $border-standard;
      border-radius: $border-radius;
      background-color: $white;
      padding: $grid-gutter-width*2.5 $grid-gutter-width*1.5;
      height: 100%;
      font-size: $font-size-sm;
      line-height: 1.25;
      color: $gray1;
      .plan-icon {
        width: $grid-gutter-width*3;
        height: auto;
        display: block;
        margin: 0 auto $grid-gutter-width*1.5;
      }
      h2 {
        margin-bottom: .5em;
        color: $body-color;
        line-height: 1;
        display: flex;
        flex: 1;
        justify-content: space-between;
        .name,.price {

        }
        .price {
          text-align: right;
          color: $gray1;
          font-size: $font-size-h4;
          line-height: 1;
          .intro {
            font-size: .66rem;
          }
          .outro {
            font-size: $font-size-xs;
          }
        }
      }
      p {
        margin-bottom: $grid-gutter-width*1.2;
      }
      .btn {
        width: 100%;
      }
      .plan-details {
        border-top: $border-standard;
        padding-top: $grid-gutter-width*1.2;
        font-size: $font-size-base;
        line-height: $line-height-base;
        h5 {
          margin-bottom: $grid-gutter-width*1.25;
        }
        ul {
          @extend .bullet-check;
        }
      }
    }
    footer {
      color: $gray1;
      .btn {
        float: right;
        margin: 0 0 $grid-gutter-width $grid-gutter-width*.75;
        @include media-breakpoint-down(xs) {
          float: none;
          display: block;
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}
/***** PRESS PAGE *****/
body.press {
  .page-header {
    display: flex;
    flex-direction: column-reverse;
    .strapline {
      color: $purple;
    }
  }
  .presskit {
    margin: $grid-gutter-height*6 0 0;

  }
}
/***** FAQ PAGE *****/
body.faq,body.page-id-1537 {
  .page-header {
    text-align: center;
/*
    margin-bottom: $grid-gutter-width;
    h1 {
      margin: 0;
    }
*/
  }
}
/***** DOWNLOAD PAGE *****/
body.download {
  .page-header {
    text-align: center;
    margin-bottom: $grid-gutter-width;
    h1 {
      margin: 0;
    }
  }
  .main {
    border: $border-standard;
    border-radius: $border-radius;
    padding-top: $grid-gutter-height*6;
    padding-bottom: $grid-gutter-height*2;
    text-align: center;
    color: $gray1;
    .wp-block-image {
      margin-bottom: $grid-gutter-width*3;
    }
    h6 {
      margin-bottom: $grid-gutter-width*3;
      color: $body-color;
    }
    .download-links {
      padding: 0;
      margin: $grid-gutter-width*2 0;
      list-style: none;
      text-align: center;
      li {
        display: inline-block;
        padding: 0;
        background-image: none;
        margin: 0 $grid-gutter-height*.75 $grid-gutter-height*.75;
        img {
          height: $grid-gutter-width*2;
          width: auto;
        }
      }
    }
    .synopsis {
      font-size: $font-size-sm;
      margin-bottom: $grid-gutter-width*3;
    }
    .reviews {
      &>.row > div {
        margin-bottom: $grid-gutter-width;
      }
      .review {
        height: 100%;
        background-color: $white-dark;
        border: $border-standard;
        border-radius: $border-radius;
        padding: $grid-gutter-width;
        font: $font-size-ui-fine $font-family-sans-ui;
        color: $body-color;
        line-height: 1.25;
        text-align: left;
        .rating {
          display: block;
          margin-bottom: $grid-gutter-width*.25;
          height: 27px;
          width: auto;
        }
        .author {
          text-transform: uppercase;
          margin-bottom: 1.2em;
          color: $gray2;
          font-weight: $font-weight-med;
          letter-spacing: .02em;
        }
        h3 {
          font: $font-weight-bold $font-size-sm $font-family-base;
          margin-bottom: 0;
          letter-spacing: normal;
        }
      }
    }
  }
}


/***** CONTACT PAGE *****/
body.page-id-57 {
  .main {
    margin-bottom: $grid-gutter-width*2;
    @include media-breakpoint-up(lg) {
      padding-top: $grid-gutter-height*5;
    }
    @include media-breakpoint-up(xl) {
      padding-top: $grid-gutter-height*6;
    }
    nav.social-links {
      margin-top: $grid-gutter-height*3;
      h5 {
        color: $gray1;
        margin-bottom: $grid-gutter-height;
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          margin: 0 $grid-gutter-height*1.5 0 0;
          padding: 0;
          background-image: none;
          display: inline-block;
          a {
            color: $gray2;
            text-decoration: none;
            font-size: $font-size-h2;
            @include hover-focus {
              color: $link-color;
            }
          }
        }
      }
    }
  }
}
/***** DEMO/CALENDLY PAGE *****/
body.schedule-demo, body.page-template-template-calendly {
  .mainwrap {
    width: 100%;
    background: url(../images/bg/chat-illustration.png) no-repeat left bottom,url(../images/bg/xray-illustration.png) no-repeat right top;
    background-size: 330px auto,310px auto;
    @include media-breakpoint-up(lg) {
      background-size: 390px auto,370px auto;
    }
    @include media-breakpoint-up(xl) {
      background-size: 430px auto,410px auto;
    }
  }
  .organizations {
    margin-top: $grid-gutter-height*3;
    text-align: center;
    img {
      display: inline-block;
      height: $grid-gutter-width*2.25;
      max-width: 135px;
      width: auto;
      margin: $grid-gutter-width $grid-gutter-width*1.5;
    }
  }
}
/***** 404 PAGE *****/
body.error404,body.agreements {
  .page-header {
    text-align: center;
  }
  .mainwrap {
    width: 100%;
    text-align: center;
    font-size: $font-size-lg;
  }
  .error-info {
    margin-bottom: $grid-gutter-width*3;
    color: $gray1;
  }
  .oops-img {
    width: 100%;
    height: auto;
    margin-top: $grid-gutter-height*6;
  }
}
body.agreements {
  .page-header {
    margin-bottom: $grid-gutter-height*4;
    h1 {
      margin-bottom: 0;
    }
  }
  .main {
    padding: $grid-gutter-width*1.5;
    border: $border-standard;
    border-radius: $border-radius;
    text-align: left;
    p {
      margin-bottom: $grid-gutter-height;
    }
    a {
      color: $gray1;
      text-decoration: none;
      @include hover-focus {
        color: $link-color;
      }
    }
  }
}
body.single-integration,body.single-partner,body.page-template-template-ip,body.page-template-template-ip-form {
  .page-header {
    text-align: center;
    padding-top: $grid-gutter-height*2.5;
    @include media-breakpoint-up(md) {
      padding-top: $grid-gutter-height*4.5;
    }
    @include media-breakpoint-up(lg) {
      padding-top: $grid-gutter-height*6;
    }
    h1 {
      color: $gray1;
      margin-bottom: $grid-gutter-height*2;
      img {
        display: block;
        margin: 0 auto $grid-gutter-height*1.5;
      }
    }
    .back-to-link {
      display: inline-block;
      float: left;
      margin-bottom: $grid-gutter-height*2;
      color: $gray1;
      font-size: $font-size-sm;
      i {
        margin-right: .5em;
      }
      @include hover-focus {
        text-decoration: none;
        color: $body-color;
      }
    }
    .ip-select {
      display: inline-block;
      width: auto;
      margin: 0 auto $grid-gutter-height*4;
      background-color: $gray6;
      border: 1px solid $gray3;
      border-radius: 9px;
      padding: 4px;
      li {
        display: inline;
        a {
          display: inline-block;
          padding: 8px 24px;
          @include media-breakpoint-up(sm) {
            padding: 8px 36px;
          }
          @include media-breakpoint-up(md) {
            padding: 8px 48px;
          }
          font-size: $font-size-sm;
          font-weight: $font-weight-bold;
          color: $body-color;
          text-decoration: none;
          border-radius: 8px;
          @include hover-focus {
            background-color: $gray5;
          }
          &.active {
            color: $white;
            background-color: $gray1;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
    h2 {
      text-align: center;
      background: linear-gradient(270deg, #2222C4 6.23%, #0094FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    .synopsis {
      text-align: center;
      font-size: $font-size-lg;
      color: $gray1;
    }
  }
}
body.page-template-template-ip {
  .page-header {
    margin-bottom: $grid-gutter-height*2;
  }
}
body.page-template-template-ip-form {
  .ip-form-title {
    text-align: center;
  }
}
/***** THANKS PAGES *****/
body.page-id-1173,
body.page-id-1429,
body.page-id-1430,
body.page-id-1431 {
  .get-started-message {
    padding: $grid-gutter-width*1.5 $grid-gutter-width*.5;
    border: $border-standard;
    border-radius: $border-radius;
    margin-top: $grid-gutter-height*4;
    @include media-breakpoint-up(md) {
      margin-top: $grid-gutter-height*5;
    }
    @include media-breakpoint-up(lg) {
      margin-top: $grid-gutter-height*6;
    }
    .row {
      @include media-breakpoint-up(lg) {
        flex-direction: row-reverse;
      }
      div {
        align-self: center;
        &:first-child {
          @include media-breakpoint-down(md) {
            padding: 0 30px;
          }
          @include media-breakpoint-only(md) {
            padding-top: $grid-gutter-width*1.5;
          }
          @include media-breakpoint-up(lg) {
            padding-left: 5%;
            padding-right: 15%;
            p:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    h1 {
      @extend .h3;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .get-started-download {
    text-align: center;
    padding: $grid-gutter-width*1.5 $grid-gutter-width*.5 0;
    margin-top: $grid-gutter-height*2;
    @include media-breakpoint-up(sm) {
      padding: $grid-gutter-width*1.5 $grid-gutter-width 0;
    }
    @include media-breakpoint-up(md) {
      margin-top: $grid-gutter-height*3;
      padding: $grid-gutter-width*2 $grid-gutter-width*3 0;
    }
    @include media-breakpoint-up(lg) {
      margin-top: $grid-gutter-height*4;
      padding: $grid-gutter-width*2 $grid-gutter-width*4 0;
    }
    h6 {
      margin-bottom: $grid-gutter-width*3;
      color: $body-color;
    }
    .download-links {
      padding: 0;
      margin: $grid-gutter-width*2 0;
      list-style: none;
      text-align: center;
      li {
        display: inline-block;
        padding: 0;
        background-image: none;
        margin: 0 $grid-gutter-height*.75 $grid-gutter-height*.75;
        img {
          height: $grid-gutter-width*2;
          width: auto;
        }
      }
    }
    .synopsis {
      font-size: $font-size-sm;
      margin-bottom: $grid-gutter-width*3;
    }
  }
}
.benefit-feature {
  margin: $grid-gutter-width*2.2 0 0;
}
.social-proof-block {
  margin-top: $grid-gutter-width*2;
  align-items: center;
  figure.social-proof-badge {
    max-width: 94px;
    padding-right: $grid-gutter-height;
    margin: 0 $grid-gutter-height 0;
    border-right: 1px solid $gray5;
    @include media-breakpoint-down(sm) {
      max-width: 102px;
      padding-right: $grid-gutter-height*1.5;
      margin: 0 $grid-gutter-height*1.5 0;
    }
  }
  .social-proof-ratings {
    align-items: center;
    .social-proof-rating {
      align-items: center;
      margin-right: $grid-gutter-height;
      &:last-child {
        margin-right: 0;
      }
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        margin-right: $grid-gutter-height*1.5;
      }
      figure.social-proof-rating-icon {
        max-width: 40px;
        margin: 0 $grid-gutter-height*.75 0 0;
        @include media-breakpoint-down(sm) {
          margin: 0 0 8px 0;
          max-width: none;
          width: 100%;
          img {
            max-width: 32px;
          }
        }
      }
      div.social-proof-rating-details {
        line-height: 1;
        font-size: $font-size-xs;
        p.rating-name {
          margin-bottom: 4px;
          white-space: nowrap;
          @include media-breakpoint-down(sm) {
            span {
              display: none;
            }
          }
        }
        p.rating-info {
          margin: 0;
          padding: 0;
          font-size: $font-size-xxs;
          line-height: 1.5;
          color: $gray2;
          img {
            max-height: 12px;
            width: auto;
            margin-right: $grid-gutter-height*.5;
          }
          @include media-breakpoint-down(sm) {
            flex-direction: column;
            img {
              margin: 0 0 2px 0;
            }
          }
          @include media-breakpoint-only(lg) {
            flex-direction: column;
            img {
              margin: 0 0 2px 0;
            }
          }
        }
      }
    }
  }
}
